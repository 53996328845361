
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { activateMixin } from '../../../../../mixins/activateMixin'
import { generalMixin } from '../../../../../mixins/generalMixin'
import { ItemOnOff, WarningMessageBox, FormTitle } from '../../FormFragments'
import { hasWarningBudgetRepartitionAppx, hasWarningKey, warningPrefillMessage } from '../../../../../../utils/BriefPrefiller/PrefillerWarning'
import BriefModel from '../../../../../models/Briefs/BriefModel'
import { DspInstruction, InstructionDsp, IoBrief, OptimizeKeys } from '../../../../../../types/instruction_type'
import { ConversionFunnelInstruction } from '../../../../../../types/brief_type'
import { rulesMixin } from '@/mixins/rulesMixin'
import { dspConfig, DspConfigType } from '../../../../../../config/dspConfig'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import { allowSwitchPacingIo } from '../../../../../../config/featureReferenceConstant'
import { IDspConfig, OverwritingFieldConfig } from '../../../../../../config/dspConfigPerDsp/dspConfigPerDspTypes'

@Component({
  components: {
    ItemOnOff,
    WarningMessageBox,
    FormTitle
  }
})
export default class BriefDspOverwriting extends mixins(generalMixin, activateMixin, rulesMixin) {
  [key: string]: any

  @Prop({ required: true, default: null }) editedItem: IoBrief<DspInstruction>
  @Prop({ required: true, default: null }) brief: BriefModel
  @Prop({ required: true, default: null }) funnel: ConversionFunnelInstruction

  showAdvancedDsp: boolean = false
  messageBriefNotConfirm: string = warningPrefillMessage

  itemUnknownDemographic: TextValue[] = [
    { text: 'always', value: 'always' },
    { text: 'only if', value: 'only_if' },
    { text: 'never', value: 'never' }
  ]

  mounted () {
    this.showAdvancedDsp = !this.isOverwritingFieldAdvancedInDefault()
  }

  getComputedThis (computedKey: string) {
    return this[computedKey]
  }

  isOverwritingFieldAdvancedInDefault () {
    let defaultItem = this.$store.getters.getDefaultItem

    for (let i in this.overwritingFields.advanced) {
      let overwritingFieldsValue = this.overwritingFields.advanced[i].value
      if (this.editedItem[overwritingFieldsValue as OptimizeKeys] !== defaultItem[overwritingFieldsValue]) {
        return false
      }
    }
    return true
  }

  checkWarningTraderBrief (key: keyof DspInstruction): boolean {
    if (!this.isTraderBriefAvailable) {
      return false
    }
    return hasWarningKey(key, this.brief, this.editedItem, this.dsp, this.funnel)
  }

  checkWarningTraderBriefBtnToggle (key: string) {
    if (!this.isTraderBriefAvailable) {
      return false
    }
    switch (key) {
      case 'overwriteBudget':
        return hasWarningBudgetRepartitionAppx(this.brief, this.editedItem, this.dsp)
      default:
        return false
    }
  }

  isDspItemOn (item: {[key: string]: any}) {
    if (item.reverseBoolean) {
      return !this.editedItem[item.value as OptimizeKeys]
    } else {
      return this.editedItem[item.value as OptimizeKeys]
    }
  }

  toggleRemoveValue (field: keyof IoBrief<DspInstruction>) {
    this.emitOpenAlertErrorDialog(field)
    this.emitOpenConfirmDialog(field)
    this.$set(this.editedItem, field, !this.editedItem[field])
  }

  emitOpenConfirmDialog (field: keyof IoBrief<DspInstruction>) {
    const futureNewValue = !this.editedItem[field]
    // if the field is allow_switch_io_pacing and the future value is true, open confirm dialog
    if (field === 'allow_switch_io_pacing' && futureNewValue) {
      this.$emit('openConfirmDialog')
    }
  }

  /**
   * Check for otto_min_viz on field remove_min_viz / keep_min_viz depending on DSP.
   * If otto_min_viz is set, open alert error dialog.
   * @param field
   */
  emitOpenAlertErrorDialog (field: keyof IoBrief<DspInstruction>) {
    if ((this.dsp === this.$APPNEXUS && field === 'remove_min_viz' && this.editedItem.otto_min_viz) ||
        (this.dsp === this.$DBM && field === 'keep_min_viz' && this.editedItem.otto_min_viz)) {
      this.$emit('openAlertErrorDialog')
    }
  }

  changeOverwriteToggle (event: number, btnToggleData: Array<any>) {
    // if 1, the 2 value are set to false
    if (event === 1) {
      this.$set(this.editedItem, btnToggleData[0].value, false)
      this.$set(this.editedItem, btnToggleData[2].value, false)
    } else if (event === 0) {
      this.$set(this.editedItem, btnToggleData[0].value, true)
      this.$set(this.editedItem, btnToggleData[2].value, false)
    } else if (event === 2) {
      this.$set(this.editedItem, btnToggleData[0].value, false)
      this.$set(this.editedItem, btnToggleData[2].value, true)
    }
  }

  isDisabledItem (key: keyof DspInstruction) {
    if (key === 'allow_switch_io_pacing') {
      return !this.editedItem.overwrite_li_budget
    }
  }

  hasTooltipItem (item: OverwritingFieldConfig) {
    return this.getTooltipValue(item)
  }

  getTooltipValue (item: OverwritingFieldConfig) {
    if (item.value === 'allow_switch_io_pacing' && !this.editedItem.overwrite_li_budget) {
      return 'This option is available only if the option Overwrite li budget has been activated.'
    }
    return item.tooltip
  }

  getOverwritingFields (fieldType: 'normal' | 'advanced'): OverwritingFieldConfig[] {
    const currentNfr: NewFeatureReferenceModel[] = this.$store.getters.getCurrentNewFeatureReferences
    const nfrPacingIo = currentNfr.find((nfr: NewFeatureReferenceModel) => nfr.devName === allowSwitchPacingIo)

    return this.overwritingFields[fieldType].filter((item: OverwritingFieldConfig) => {
      if (item.value === 'allow_switch_io_pacing') {
        return nfrPacingIo && nfrPacingIo.isFeatureOn(this.dsp)
      }
      return true
    })
  }

  // GETTER HELPER
  get overwriteBudget () {
    if (this.editedItem.remove_daily_budget_global && !this.editedItem.remove_daily_budget_campaigns_only) {
      return 0
    } else if (!this.editedItem.remove_daily_budget_global && this.editedItem.remove_daily_budget_campaigns_only) {
      return 2
    } else {
      return 1
    }
  }

  get activateUnknownDemographic () {
    return [this.$DBM, this.$YOUTUBE].includes(this.dsp)
  }

  // GETTERS
  get isTraderBriefAvailable (): boolean {
    return this.brief && this.brief instanceof BriefModel && !this.brief.isNoteBrief()
  }
  get overwritingFields (): IDspConfig['overwritingFields'] {
    if (Object.keys(dspConfig[this.dsp as InstructionDsp]).indexOf('overwritingFields') === -1) {
      console.warn(`overwritingFields is not a key of dspConfig for the dsp ${this.dsp}. No overwriting checkbox will be displayed.`)
      return {
        normal: [],
        advanced: []
      }
    }
    return dspConfig[this.dsp as InstructionDsp].overwritingFields
  }

  get overwritingFieldsNormal (): OverwritingFieldConfig[] {
    return this.getOverwritingFields('normal')
  }

  get overwritingFieldsAdvanced (): OverwritingFieldConfig[] {
    return this.getOverwritingFields('advanced')
  }
}

