import { ConversionFunnelInstruction, ConversionFunnelTemplate, FunnelId, FunnelStep, Segment, Pixel, FunnelStepArgs } from './brief_type'
import { FunnelOrigin, FunnelType } from './brief_enum'
import BriefModel from '../src/models/Briefs/BriefModel'

export function isInstanceOfPixel (obj: any): obj is Pixel {
  return 'type' in obj && obj['type'] === 'pixel'
}

export function isInstanceOfSegment (obj: any): obj is Segment {
  return 'type' in obj && obj['type'] === 'segment'
}

function convertionFunnelTemplateToConvertionFunnel (funnelTemplate: ConversionFunnelTemplate, target: FunnelOrigin = FunnelOrigin.DSP): ConversionFunnelInstruction {
  const funnel: ConversionFunnelInstruction = {}
  for (let i in funnelTemplate) {
    const funnelStep: FunnelStep = funnelTemplate[i]
    const funnelIdArray: FunnelId[] = []
    for (let y in funnelStep) {
      const segmentOrPixel: Segment | Pixel = funnelTemplate[i][y]
      funnelIdArray.push({
        id: String(segmentOrPixel.id),
        type: segmentOrPixel.type === FunnelType.SEGMENT ? FunnelType.SEGMENT : FunnelType.PIXEL,
        origin: target
      })
    }
    funnel[String(Number(i) + 1)] = funnelIdArray
  }
  return funnel
}

function funnelStepArgsToFunnelStep (funnelStepArgs: FunnelStepArgs, segments: Segment[], pixels: Pixel[]): ConversionFunnelTemplate {
  let funnelStep: ConversionFunnelTemplate = []

  if (!Array.isArray(funnelStepArgs)) {
    return []
  }

  for (let i in funnelStepArgs) {
    let step = funnelStepArgs[i]

    for (let y in step) {
      let subStep = step[y]
      let currentItem: Segment | Pixel = null

      if (subStep.type === 'segment') {
        currentItem = segments.filter(item => item.id === subStep.id)[0]
      } else {
        currentItem = pixels.filter(item => item.id === subStep.id)[0]
      }

      if (funnelStep[i] === undefined) {
        funnelStep[i] = []
      }
      funnelStep[i].push(currentItem)
    }
  }
  return funnelStep
}

function recomposeFrequencyCap (brief: BriefModel) {
  return `<strong>${brief.constraints.frequencyCapExposure}</strong> imp per <strong>${brief.constraints.frequencyCapAmount}</strong> <strong>${brief.constraints.frequencyCapPeriod}</strong>`
}

function recomposeFrequencyTarget (brief: BriefModel) {
  return `<strong>${brief.constraints.frequencyTargetExposure}</strong> imp per <strong>${brief.constraints.frequencyTargetAmount}</strong> <strong>${brief.constraints.frequencyTargetPeriod}</strong>`
}

export { convertionFunnelTemplateToConvertionFunnel, funnelStepArgsToFunnelStep, recomposeFrequencyCap, recomposeFrequencyTarget }
