
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getColorForPercentage } from '../../../../../utils/FitScoreColor/colorScore'
import { roundNum } from '../../../../../utils/commonUtils'

@Component({})
export default class FitScore extends Vue {
    @Prop()
    fitScoreValue: number

    readonly MAX_SCORE = 100

    getScoreColor () {
      return getColorForPercentage(this.fitScoreValue / 100)
    }

    get messageTooltip () {
      return `Your fit score is ${this.fitScoreValue} / ${this.MAX_SCORE}`
    }

    get fitScore () {
      if (typeof this.fitScoreValue !== 'number') {
        return null
      }
      return roundNum(this.fitScoreValue, 0)
    }
}
