
import { defineComponent } from 'vue'
import SimpleDialog from './SimpleDialog.vue'
import InputFile from '../../../UtilityComponents/InputFile.vue'

export default defineComponent({
  name: 'CsvInputFileDIalog',
  components: {
    SimpleDialog,
    InputFile
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    addComment: {
      type: Boolean,
      required: false,
      default: false
    },
    emitContentBtnLabel: {
      type: String,
      required: false,
      default: 'upload'
    }
  },
  data () {
    return {
      dialogOn: false,
      fileContent: null,
      comment: null
    }
  },
  mounted () {
    this.dialogOn = this.value
  },
  methods: {
    close () {
      this.reset()
      this.dialogOn = false
      this.$emit('closeSimpleDialog')
    },
    emitContent () {
      this.$emit('emitContent', this.fileContent, this.comment)
      this.reset()
    },
    errorOccured (error: String) {
      console.warn('Not yet implemented.', error)
    },
    inputSuccess (fileContent: string) {
      this.fileContent = fileContent
    },
    reset () {
      this.fileContent = null
      this.comment = null
    }
  },
  computed: {},
  watch: {
    value (newValue: boolean, oldValue: boolean) {
      if (newValue !== oldValue && this.dialogOn !== newValue) {
        this.dialogOn = newValue
      }
    }
  }
})

