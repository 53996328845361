
import { PropType, defineComponent } from 'vue'

const CSV_TYPE = 'text/csv'

export default defineComponent({
  name: 'InputFile',
  components: {},
  props: {
    label: {
      type: String,
      required: false,
      default: 'Browse File...'
    },
    acceptedFileType: {
      type: String,
      required: false,
      default: CSV_TYPE
    },
    browseBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    browseBtnColor: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  data () {
    return {
      selectedFile: null,
      fileType: String
    }
  },
  mounted () {},
  methods: {
    inputFile () {
      const component = document.getElementById('inputFile')
      if (component) {
        component.click()
      }
    },
    fileHandler (file: any) {
      if (file == null) {
        return
      }
      if (file.type !== this.acceptedFileType) {
        const error = 'Wrong file type provided. Aborting.'
        console.warn(error)
        this.emitError(error)
        return null
      }
      let content = null
      switch (file.type) {
        case CSV_TYPE:
          content = this.csvFileHandler(file)
          break
        default:
          break
      }
    },
    csvFileHandler (file: any) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const target = event.target
        if (target == null) {
          return null
        }
        this.emitSuccess(target.result)
      }
      reader.readAsText(file)
    },
    // EMITTERS
    emitSuccess (content: any) {
      this.$emit('success', content)
    },
    emitError (error: String) {
      this.$emit('error', error)
    }
  },
  computed: {},
  watch: {}
})
