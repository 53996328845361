<template>
  <v-row class="label-value-layout" v-if="!ioFormTopbar">
    <v-col
      :cols="longLabel ? 7 : (littleLabel && !longLabel ? 2 : 5)"
      :xs5="!littleLabel" :sm5="!littleLabel" :md5="!littleLabel"
      :xs2="littleLabel && !longLabel" :sm2="littleLabel && !longLabel" :md2="littleLabel && !longLabel"
      :xs7="longLabel" :sm7="longLabel" :md7="longLabel">
      <strong>{{label}}</strong>
    </v-col>
    <v-col
      :class="{ 'txt-overflow-ellipsis': ellipsis }"
      :cols="longLabel ? 5 : (littleLabel && !longLabel ? 10 : 7)"
      :xs7="!littleLabel" :sm7="!littleLabel" :md7="!littleLabel"
      :xs10="littleLabel && !longLabel" :sm10="littleLabel && !longLabel" :md10="littleLabel && !longLabel"
      :xs5="longLabel" :sm5="longLabel" :md5="longLabel">
      {{value}}
    </v-col>
  </v-row>
  <v-row v-else column>
    <v-col cols="12" class="pb-0"><strong class="text-white">{{label}}</strong></v-col>
    <v-col cols="12" :class="'text-truncate' ? 'text-line-clamp pt-0' : 'pt-0'"><span class="text-white">{{value}}</span></v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LabelValue',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true,
      default: 'NC'
    },
    littleLabel: {
      type: Boolean,
      default: false
    },
    longLabel: {
      type: Boolean,
      default: false
    },
    ellipsis: {
      type: Boolean,
      default: false
    },
    ioFormTopbar: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.label-value-layout {
  font-size: 8px;
}

.txt-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 40px;
}
.text-white {
  color: white;
}

.text-line-clamp {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
