
import { defineComponent, PropType } from 'vue'
import { rulesMixin } from '../../../../../mixins/rulesMixin'
import PivotVariablesAutocomplete
  from '@/components/TableComponents/Form/FormFragments/3PObjectWatcher/PivotVariablesAutocomplete.vue'
import { InsGetterSetter, P3ObjWatcher } from '../../../../../../types/instruction_type'
import { AvailableKpi } from '../../../../../../types/third_party_gen_type'

type KpiTypeSource = { kpiType?: string, source?: string }

export default defineComponent({
  name: 'ThirdPartyConstraints',
  mixins: [rulesMixin],
  components: {
    PivotVariablesAutocomplete
  },
  props: {
    insGetterSetter: {
      type: Object as PropType<InsGetterSetter>,
      required: true
    }
  },
  data () {
    return {
      selectedFieldTargetValue: null as NumberKeys<P3ObjWatcher> | null,
      itemsMinMaxValue: [
        { text: 'Min Value', value: 'min_target_value' },
        { text: 'Max Value', value: 'max_target_value' }
      ] as TextValueType<keyof P3ObjWatcher>[]
    }
  },
  mounted () {
    this.setValueSelectedFieldTargetValue()
  },
  methods: {
    setValueSelectedFieldTargetValue () {
      if (this.insGetterSetter?.thirdPartyObjWatcher?.max_target_value) {
        this.selectedFieldTargetValue = 'max_target_value'
      } else if (this.insGetterSetter?.thirdPartyObjWatcher?.min_target_value) {
        this.selectedFieldTargetValue = 'min_target_value'
      } else {
        this.selectedFieldTargetValue = null
      }
    },
    resetValues () {
      if (this.selectedAvailableKpis === null) {
        this.selectedFieldTargetValue = null
        this.insGetterSetter.thirdPartyObjWatcher = {
          measurement_source: null,
          kpi_type: null,
          min_target_value: null,
          max_target_value: null,
          conversion_funnel_pixel_ids: null
        }
        this.insGetterSetter.thirdPartyObjWatcherPivotVariables = []
      } else {
        this.insGetterSetter.thirdPartyObjWatcher = {
          ...this.insGetterSetter.thirdPartyObjWatcher,
          min_target_value: null,
          max_target_value: null
        }
      }
    },
    resetOtherTargetField () {
      const p3ObjWatcher = this.insGetterSetter.thirdPartyObjWatcher
      const fieldToReset = this.selectedFieldTargetValue === 'min_target_value' ? 'max_target_value' : 'min_target_value'
      this.insGetterSetter.thirdPartyObjWatcher = {
        ...p3ObjWatcher,
        [fieldToReset]: null
      }
    },
    setDefaultValueAlphaParam () {
      if (this.insGetterSetter.thirdPartyObjWatcher.kpi_type &&
      !this.insGetterSetter.thirdPartyObjWatcherAlpha) {
        this.insGetterSetter.thirdPartyObjWatcherAlpha = 0.5
      }
    }
  },
  computed: {
    availableKpisItems (): TextValueType<KpiTypeSource>[] {
      const availableKpis: AvailableKpi[] = this.$store.getters.getAvailableKpis
        ? this.$store.getters.getAvailableKpis : []
      return availableKpis.map((kpi: AvailableKpi) => {
        return {
          text: `${kpi.display_name} by ${kpi.source}`,
          value: {
            kpiType: kpi.kpi_type,
            source: kpi.source
          }
        }
      }).concat([{ text: 'No 3rd party KPI used as a constraint', value: null }])
    },
    displayUnitAndPivot (): boolean {
      return this.selectedAvailableKpis !== null
    },
    selectedAvailableKpis: {
      get (): KpiTypeSource | null {
        return this.insGetterSetter?.thirdPartyObjWatcher?.kpi_type
          ? {
            kpiType: this.insGetterSetter.thirdPartyObjWatcher.kpi_type,
            source: this.insGetterSetter.thirdPartyObjWatcher.measurement_source
          }
          : null
      },
      set (value: KpiTypeSource | null) {
        const p3ObjWatcher = this.insGetterSetter.thirdPartyObjWatcher
        this.insGetterSetter.thirdPartyObjWatcher = {
          ...p3ObjWatcher,
          kpi_type: value?.kpiType ? value.kpiType : null,
          measurement_source: value?.source ? value.source : null
        }
      }
    },
    unit (): string {
      return this.selectedAvailableKpis !== null
        ? this.$store.getters.getAvailableKpis.find((kpi: AvailableKpi) => kpi.kpi_type === this.selectedAvailableKpis?.kpiType)?.unit
        : ''
    },
    unitLabel (): string {
      switch (this.unit) {
        case 'percent':
          return '%'
        case 'currency':
          const overviewAttributes = this.$store.getters.getOverviewAttributes
          const currency = overviewAttributes?.currency
          return currency || ''
        default:
          return this.unit
      }
    },
    selectedUnit: {
      get (): number | null {
        if (this.selectedFieldTargetValue === null) return null
        let value = this.insGetterSetter.thirdPartyObjWatcher[this.selectedFieldTargetValue] as number | null
        if (this.unit === 'percent') {
          value = value ? value * 100 : null
        }
        return value
      },
      set (value: number | null) {
        const p3ObjWatcher = this.insGetterSetter.thirdPartyObjWatcher

        if (this.unit === 'percent') {
          value = value ? value / 100 : null
        }

        this.insGetterSetter.thirdPartyObjWatcher = {
          ...p3ObjWatcher,
          [this.selectedFieldTargetValue]: value
        }
      }
    },
    isSuffixUnit (): boolean {
      return this.unit === 'percent'
    }
  },
  watch: {
    'insGetterSetter.thirdPartyObjWatcher.kpiType': {
      immediate: false,
      deep: true,
      handler () {
        this.setDefaultValueAlphaParam()
      }
    }
  }
})
