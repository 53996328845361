<template>
  <v-row wrap class="pt-4">
    <v-col cols="12" v-if="isNote">
      <v-col cols="12">
        <h3>Notes</h3>
      </v-col>
      <v-col v-for="(item, index) in notes" :key="index" cols="12">
        <div>{{new Date(item.insertion_date).toString()}}</div>
        <v-textarea
          label="comments"
          :value="item.content"
          readonly
          outlined
          hide-details
        >
        </v-textarea>
        <div class="pt-6" v-if="item && displayFitscore(new Date(item.insertion_date))">
          <FitScoreTraderView
            :fitscore="item.fitscore_at_submission_time"
            :insertion-date="item.insertion_date"
          >
          </FitScoreTraderView>
        </div>
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-row wrap v-if="brief && brief.comments" class="pb-6">
        <v-col cols="12">
          <h3>Comments</h3>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="comments"
            :value="brief.comments"
            readonly
            outlined
            hide-details
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row v-if="loading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-row>
      <v-row v-if="!loading && !brief">
        <v-alert style="width: 80%" type="info">
          Can not find template brief for this io
        </v-alert>
      </v-row>
      <v-row v-if="!loading && brief && !isNote">
        <v-col cols="12">
          <v-row wrap>
            <v-col cols="12">
              <h3>Main objective</h3>
            </v-col>
            <v-col class="pr-12" cols="12" v-for="(value, index) in getMainObjectiveConfig()" :key="index">
              <v-row wrap>
                <v-col cols="6" class="pt-6 pr-2 allow-disallow">
                  <div v-for="(item, indexValue) in value.items" :key="indexValue">
                    <div v-if="item.value" class="pr-2">
                      The {{item.label}} is <strong>{{item.value}}</strong> {{item.unit ? item.unit : ''}}
                    </div>
                    <div v-else-if="!item.doNotDisplayIfNull" class="pr-2">
                      No {{item.label}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" v-if="value.comment">
                  <v-textarea
                    color="primary"
                    label="comments"
                    height="70px"
                    :value="value.comment"
                    outlined
                    hide-details
                    readonly
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="pt-6" v-if="displayFunnel">
              <v-row wrap>
                <v-col cols="12">
                  <h3>Funnel</h3>
                  <ConversionFunnelComponent
                    :conversion-funnel="funnel"
                  >
                  </ConversionFunnelComponent>
                </v-col>
                <v-col class="pl-6 pr-12" cols="12" v-if="brief.commentFunnel">
                  <v-textarea
                    color="primary"
                    label="comments"
                    height="70px"
                    :value="brief.commentFunnel"
                    outlined
                    hide-details
                    readonly
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-6" v-if="brief && displayFitscore(new Date(brief.insertionDate))">
              <FitScoreTraderView
                :fitscore="brief.fitScoreAtSubmissionTime"
                :insertion-date="brief.insertionDate"
              >
              </FitScoreTraderView>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row wrap>
            <v-col cols="12">
              <h3>Additional constraints</h3>
            </v-col>
            <v-col class="pr-12" cols="12" v-for="(value, index) in getAdditionalConstraintsConfig()" :key="index">
              <v-row wrap>
                <v-col cols="6" class="pt-4 pr-2 allow-disallow">
                  <div v-if="value.isAllow" class="pt-6">
                    <span v-html="value.allow ? value.allow : getDefaultAllow(value.label)"></span>
                  </div>
                  <div v-else class="pt-6">
                    <span v-if="value.constraints">
                       <span v-if="value.disallowSentence == null">No <strong>{{value.label}}</strong> constraints</span>
                        <span v-else v-html="value.disallowSentence"></span>
                    </span>
                    <span v-else v-html="value.allow ? value.allow : getDefaultDisallow(value.label)"></span>
                  </div>
                </v-col>
                <v-col cols="6" v-if="value.comment">
                  <v-textarea
                    color="primary"
                    label="comments"
                    height="70px"
                    :value="value.comment"
                    outlined
                    hide-details
                    readonly
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BriefModel from '@/models/Briefs/BriefModel'
import ConversionFunnelComponent from '@/components/Common/ConversionFunnelComponent'
import FitScoreTraderView from '@/components/TableComponents/Form/FormFragments/FitScoreTraderView'
import { recomposeFrequencyCap, recomposeFrequencyTarget } from '../../../../../types/brief_type_helper'

const ALLOW_SENTENCE = 'AI has freedom to adjust'
const DISALLOW_SENTENCE = 'AI has not freedom to adjust'

export default {
  name: 'TraderViewComponent',
  components: { FitScoreTraderView, ConversionFunnelComponent },
  props: {
    brief: {
      type: BriefModel
    },
    funnel: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    externalId: {
      type: String
    },
    dsp: {
      type: String
    },
    clientId: {
      type: [String, Number],
      required: false
    }
  },
  data: function () {
    return {}
  },
  methods: {
    getMainObjectiveConfig () {
      if (!this.brief) {
        return []
      }
      return [
        {
          items: [
            {
              label: 'Main budget',
              value: this.brief.budgetToSaturateInPriority
            }
          ],
          comment: this.brief.commentBudgetMicro
        },
        {
          items: [
            {
              label: 'Business model',
              value: this.brief.revenueType
            },
            {
              label: 'Rev CPM',
              value: this.brief.revCPM,
              doNotDisplayIfNull: true
            }
          ],
          comment: this.brief.commentBusinessModel
        },
        {
          items: [
            {
              label: 'Margin constraints',
              value: this.brief.minMargin
            }
          ],
          comment: this.brief.commentMarginConstraints
        },
        {
          items: [
            {
              label: 'Main Kpi',
              value: this.brief.KPI
            },
            {
              label: 'Target',
              value: this.brief.boostTarget,
              unit: this.brief.boostTargetUnit
            },
            {
              label: 'Conversion measurement tool',
              value: this.brief.conversionMeasurementTool
            }
          ],
          comment: this.brief.commentMainKpi
        },
        {
          items: [
            {
              label: 'Min CPM',
              value: this.brief.minCpm
            },
            {
              label: 'Max CPM',
              value: this.brief.maxCpm
            },
            {
              label: 'Max CPCV',
              value: this.brief.maxCPCV
            },
            {
              label: 'Max CPC',
              value: this.brief.maxCPC
            }
          ],
          comment: this.brief.commentMainConstraints
        }
      ]
    },
    getAdditionalConstraintsConfig () {
      if (!this.brief) {
        return []
      }
      return [
        {
          label: 'View Rate',
          isAllow: this.brief.constraints.allowViewRate,
          allow: `Average View Rate should be at least <strong>${this.brief.constraints.viewRate}</strong> %`,
          comment: this.brief.commentViewRate,
          constraints: true
        },
        {
          label: 'Frequency cap',
          isAllow: (this.brief.constraints.overwriteFrequencyMode != null &&
              ['allowed_with_constraints'].includes(this.brief.constraints.overwriteFrequencyMode)) ||
            (this.brief.constraints.overwriteFrequencyMode == null && this.brief.constraints.allowFrequencyCap),
          allow: 'Frequency cap : ' + recomposeFrequencyCap(this.brief),
          comment: this.brief.commentFrequencyCap,
          constraints: true,
          disallowSentence: this.brief.constraints.overwriteFrequencyMode === 'not_allowed'
            ? 'AI is not allowed to overwrite frequency'
            : 'No <strong>Frequency cap</strong> constraints'
        },
        {
          label: 'Frequency target',
          isAllow: this.brief.KPI === 'reach_and_frequency' && this.brief.constraints.allowFrequencyTarget,
          allow: 'Frequency target : ' + recomposeFrequencyTarget(this.brief),
          comment: this.brief.commentFrequencyTarget,
          constraints: true
        },
        {
          label: 'Line Item Viewability settings',
          isAllow: this.brief.constraints.viewabilitySettings,
          comment: this.brief.commentViewabilitySettings
        },
        {
          label: 'Line Item budgets',
          isAllow: this.brief.constraints.budgetRepartition,
          comment: this.brief.commentBudgetRepartition
        },
        {
          label: 'Time Parting Settings',
          isAllow: this.brief.constraints.timeParting,
          comment: this.brief.commentTimeParting
        },
        {
          label: 'Fold position Settings',
          isAllow: this.brief.constraints.foldPosition,
          comment: this.brief.commentFoldPosition
        },
        {
          label: 'Activity Status of creative',
          isAllow: this.brief.constraints.creativeActivity,
          comment: this.brief.commentCreativeActivity
        },
        {
          label: 'Automatically remove deprecated url',
          isAllow: this.brief.constraints.automaticallyRemoveDeprecatedUrl,
          comment: this.brief.commentAutomaticallyRemoveDeprecatedUrl,
          notDisplay: ![this.$DBM, this.$YOUTUBE].includes(this.dsp)
        },
        {
          label: 'Overwrite bid adjustment on device',
          isAllow: this.brief.constraints.overwriteBidAdjustmentsOnDevice,
          allow: this.brief.constraints.overwriteBidAdjustmentsOnDevice
            ? 'AI has freedom to modify bid adjustment on devices at Ad-Group Level unless bid adjustment is equal to Decrease by 100%'
            : null,
          comment: this.brief.commentAutomaticallyRemoveDeprecatedUrl,
          notDisplay: ![this.$YOUTUBE].includes(this.dsp)
        },
        {
          label: 'Overwrite unknown demographic',
          isAllow: this.brief.constraints.allowUnknownDemographic,
          allow: 'Overwrite unknown demographic : ' + this.brief.constraints.allowUnknownDemographic,
          comment: this.brief.commentAutomaticallyRemoveDeprecatedUrl,
          notDisplay: ![this.$DBM, this.$YOUTUBE].includes(this.dsp)
        }
      ].filter(item => !item.notDisplay)
    },
    getDefaultAllow (label) {
      return `${ALLOW_SENTENCE} <strong>${label}</strong>`
    },
    getDefaultDisallow (label) {
      return `${DISALLOW_SENTENCE} <strong>${label}</strong>`
    },
    /**
     * We do not want to display the fitscore component if the insertion date of the brief if before the mep date
     * @param insertionDate {Date}
     * @returns {boolean}
     */
    displayFitscore (insertionDate) {
      let dateMepFitscore = new Date(2021, 5, 7)
      return insertionDate > dateMepFitscore
    }
  },
  computed: {
    displayFunnel () {
      return typeof this.funnel === 'object' && ['CPA', 'CPA_PC'].includes(this.brief.KPI)
    },
    isNote () {
      return (this.brief && Array.isArray(this.brief.notes) && this.brief.notes.length) || (this.brief && this.brief.text)
    },
    notes () {
      return this.brief && Array.isArray(this.brief.notes) && this.brief.notes.length
        ? this.brief.notes
        : [{
          content: this.brief.text,
          insertion_date: this.brief.insertionDate,
          fitscore_at_submission_time: this.brief.fitScoreAtSubmissionTime,
          username: ''
        }]
    }
  }
}
</script>

<style scoped>
.allow-disallow {
  font-size: 10px;
}
</style>
