import { getAllMethodOfClassStartingBy } from '../classUtils'

const ERROR = 'error'
const WARNING = 'warning'

class RulesChecker {
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param args
   */
  checkErrors (editedItem, instructionContainer, ...args) {
    return this._check(editedItem, instructionContainer, ERROR, ...args)
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param args
   */
  checkWarnings (editedItem, instructionContainer, ...args) {
    return this._check(editedItem, instructionContainer, WARNING, ...args)
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param type {'error'|'warning'}
   * @param args
   */
  _check (editedItem, instructionContainer, type, ...args) {
    const errorFunctions = this._getAllFunctionName(type)
    let errorsList = []
    let errorNameList = []
    for (let i in errorFunctions) {
      let result = this[errorFunctions[i]](editedItem, instructionContainer, ...args)
      if (result) {
        errorNameList.push(errorFunctions[i])
        errorsList.push(result)
      }
    }
    return {
      errors: errorsList,
      names: errorNameList
    }
  }

  _isset (value) {
    return !!value
  }

  /**
   * @param obj
   * @param nestedPath {String}
   */
  issetInObject (obj, nestedPath) {
    let splitted = nestedPath.split('.')
    let currentObj = obj

    for (let i in splitted) {
      if (!this._isset(currentObj[splitted[i]])) {
        return false
      }

      if (i !== splitted.length) {
        currentObj = currentObj[splitted[i]]
      }
    }

    return true
  }

  _getAllFunctionName (type) {
    return getAllMethodOfClassStartingBy(this, type)
  }
}

export {
  RulesChecker
}
