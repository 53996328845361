<template>
  <v-alert type="error">
    <v-row>
      <v-col>
        <span v-html="errorText"></span>
      </v-col>
      <v-col class="ml-4 align-self-center">
        <v-btn color="primary" @click="emitClose()">
          Close
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertErrorDialog',
  props: {
    errorText: {
      type: String,
      required: true,
      default: '[No text provided]'
    }
  },
  data: function () {
    return {}
  },
  methods: {
    emitClose () {
      this.$emit('close-alert-error')
    }
  }
}
</script>

<style scoped>
.text-bold {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
