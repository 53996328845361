
import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import InstructionGetterSetter from '../../../../../models/Overlay/InstructionGetterSetter'
import {
  ShowAdvanced,
  MatchingTitle,
  LinedTextarea,
  FormTitle,
  InheritInput, PercentageTextField
} from '../../FormFragments'
import { activateMixin } from '../../../../../mixins/activateMixin'
import * as commonUtils from '../../../../../../utils/commonUtils'
import * as rules from '../../../../../rules/rules'
import { generalMixin } from '../../../../../mixins/generalMixin'
import { MatchingParser, ReverseMatchingParser } from '../../../../../../utils/matchingParser.js'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'

@Component({
  components: {
    ShowAdvanced,
    MatchingTitle,
    LinedTextarea,
    FormTitle,
    InheritInput,
    PercentageTextField
  }
})
export default class BriefAlgoStrategy extends mixins(generalMixin, activateMixin, checkPremepValidatorMixins) {
  [key: string]: any // Vue.prototype variables type

  @Prop({ required: true, default: '' }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: true, default: false }) displayBordersAlgoStrategy: boolean
  @Prop({ required: true, default: false }) input: InputType

  rules = rules

  inheritFromHighestAllowedCPM: boolean = true
  inheritFromLowestAllowedCPM: boolean = true

  showAdvancedStrategy: boolean = false

  lazyMatchingOne: string = ''
  errorsMatchingLi: Array<string> = []
  matchingParserLi = new MatchingParser()
  reverseMatchingParser = new ReverseMatchingParser()

  mounted () {
    this.setInheritValues()
    this.setValueMatching()
    this.showAdvancedStrategy = !this.isAlgoFieldInDefault
  }

  setInheritValues () {
    this.setInheritFromExpectedMargin()
    this.setInheritFromHighestAllowedCPM()
    this.setInheritFromLowestAllowedCPM()
  }

  toggleItemRecency () {
    this.insGetterSetter.itemRecency = !this.insGetterSetter.itemRecency
  }

  /**
   * get value from 'matching_cp' and 'matching_li', reverse parse for the text area,
   * and put her in 'lazyMatchingTwo' and 'lazyMatchingOne'
   */
  setValueMatching () {
    let matchingFields = ['matchingOnePerDsp']
    let lazyMatchingFields = ['lazyMatchingOne']

    for (let i in matchingFields) {
      let matching = this[matchingFields[i]]
      // parse received value and put it in lazy value
      this[lazyMatchingFields[i]] = this.reverseMatchingParser.reverseParse(matching)
    }
  }

  // INIT ERROR CHECK
  get marginDailyInitErrorCheck () {
    return !this.inheritFromExpectedMargin && (this.inheritMarginDaily == null || this.inheritMarginDaily === '')
  }
  get highestAllowedInitErrorCheck () {
    return !this.inheritFromHighestAllowedCPM && (this.inheritHighestCPM == null || this.inheritHighestCPM === '')
  }
  get lowestAllowedInitErrorCheck () {
    return !this.inheritFromLowestAllowedCPM && (this.inheritLowestCPM == null || this.inheritLowestCPM === '')
  }

  // INHERIT GETTERS SETTERS
  sameInheritedValueOrEmptyValue (value1: number | string, value2: number) {
    return this.areSameInheritValue(value1, value2) || this.checkEmptyNotInheritedAndEmptyValue(value1)
  }
  areSameInheritValue (value1: number | string, value2: number) {
    value1 = typeof value1 === 'string' && value1 === '' ? null : value1
    return (!value1 && !value2) || (Number(value1) === Number(value2))
  }
  checkEmptyNotInheritedAndEmptyValue (value: number | string) {
    return typeof value === 'string' && value.trim() === ''
  }
  setInheritFromExpectedMargin () {
    this.inheritFromExpectedMargin = this.areSameInheritFromExpectedMargin()
  }
  areSameInheritFromExpectedMargin () {
    return this.areSameInheritValue(this.insGetterSetter.marginDaily, this.insGetterSetter.expectedMargin)
  }
  setInheritFromHighestAllowedCPM () {
    this.inheritFromHighestAllowedCPM = this.areSameInheritFromInheritHighestCPM()
  }
  areSameInheritFromInheritHighestCPM () {
    return this.areSameInheritValue(this.insGetterSetter.constraintsMaxCpm, this.insGetterSetter.highestAllowedCPM)
  }
  setInheritFromLowestAllowedCPM () {
    this.inheritFromLowestAllowedCPM = this.areSameInheritFromLowestAllowedCPM()
  }
  areSameInheritFromLowestAllowedCPM () {
    return this.areSameInheritValue(this.insGetterSetter.constraintsMinCpm, this.insGetterSetter.lowestAllowedCPM)
  }

  // INHERIT RULES
  marginDailyErrorMessages () {
    let error = []
    if (!this.inheritFromExpectedMargin) {
      if (this.sameInheritedValueOrEmptyValue(this.insGetterSetter.marginDaily, this.insGetterSetter.expectedMargin)) {
        this.showAdvancedStrategy = true
        error.push('You must set inherit from expected margin if you want that margin daily and expected margin are equal.')
      }
      let result = rules.isBetween(this.insGetterSetter.marginDaily, 0, 1, 'Must be between 0 and 100.')
      if (result !== undefined) {
        error.push(result)
      }
    }

    if (!this.isWarningCPMValidator()) {
      error = [...error, ...this.getErrorRelatedToFieldCPMValidator(['constraints_io.margin_daily'])]
    }

    return error
  }

  getHintMarginDaily () {
    if (!this.isWarningCPMValidator()) {
      return ''
    }
    const errors = this.getWarningRelatedToFieldCPMValidator(['constraints_io.margin_daily'])
    return errors.length > 0 ? errors.join(' ') : ''
  }

  highestAllowedErrorMessages (): string[] {
    let errors = []
    if (this.inheritFromHighestAllowedCPM) {
      return []
    }
    if (!this.inheritFromHighestAllowedCPM && this.sameInheritedValueOrEmptyValue(this.insGetterSetter.constraintsMaxCpm, this.insGetterSetter.highestAllowedCPM)) {
      this.showAdvancedStrategy = true
      errors.push('You must set inherit from highest allowed CPM if you want that field to be equal to highest allowed cpm.')
    }

    let errorMin = this.rules.minimumValue(this.insGetterSetter.constraintsMaxCpm, 0, true)
    if (typeof errorMin === 'string') {
      errors.push(errorMin)
    }
    return errors
  }
  lowestAllowedErrorMessages (): string[] {
    let errors = []
    if (this.inheritFromLowestAllowedCPM) {
      return []
    }
    if (!this.inheritFromLowestAllowedCPM && this.sameInheritedValueOrEmptyValue(this.insGetterSetter.constraintsMinCpm, this.insGetterSetter.lowestAllowedCPM)) {
      this.showAdvancedStrategy = true
      errors.push('You must set inherit from lowest allowed CPM if you want that field to be equal to lowest allowed cpm.')
    }

    let errorMin = this.rules.minimumValue(this.insGetterSetter.constraintsMinCpm, 0, true)
    if (typeof errorMin === 'string') {
      errors.push(errorMin)
    }
    return errors
  }

  launchTimeOutBadge () {
    this.$emit('launchTimeOutBadge')
  }

  matchingAreSet () {
    return commonUtils.isNotNullOrUndefined(this.matchingOnePerDsp) && this.matchingOnePerDsp.trim() !== '' && this.matchingOnePerDsp.trim() !== '{}'
  }

  errorMessageMaxCutOff (): string {
    if (!this.insGetterSetter.maxCutoff) { return '' }
    return rules.isBetween(this.insGetterSetter.maxCutoff, 0, 1, 'max CutOff\'s value must be a decimal value stricly between 0 and 1', false, true)
  }

  // parse value in the matching text area and (if parsing has no errors) transform it to a chain conform with the datastore
  lazyMatchingProcess (lazyValue: string, parser: string, errorsMatching: string, editedItemField: string) {
    this[parser].allowString(this.dsp === this.$THETRADEDESK)

    let parsedData = this[parser].parseString(lazyValue)
    this[errorsMatching] = []

    if (parsedData === false) {
      this[errorsMatching] = this[parser].getErrors()
    } else {
      this[editedItemField] = parsedData
    }

    this.launchTimeOutBadge()
  }

  maxDivMaxMultHint (computedValue: 'campaignMaxDiv' | 'campaignMaxMult') {
    if (this.insGetterSetter[computedValue] >= 3) {
      return 'Warning, you\'ve entered a value over 3, please check that the budget has changed by a factor greater than 3 and do not forgot to remove it once plugged to prevent overspending'
    }
    return ''
  }

  hintMaxA () {
    if (this.insGetterSetter.maxA) {
      return 'Warning: Inserting a Max A value might limit the spending of your IO & generate severe underpacing,use it only if necessary.'
    }
    return null
  }

  errorMessagesAlphaParam (): string[] {
    const errors: string[] = []

    if (this.insGetterSetter?.thirdPartyObjWatcher?.kpi_type &&
      (this.insGetterSetter?.thirdPartyObjWatcherAlpha == null || this.insGetterSetter?.thirdPartyObjWatcherAlpha === '')) {
      let message = 'Please fill the 3rd Party Constraint Alpha Param in the Algo strategy part, ' +
        'it’s mandatory when using a 3rd party KPI as a constraint'
      errors.push(message)
    }

    if (this.insGetterSetter.thirdPartyObjWatcherAlpha !== '' &&
      rules.ruleIsBetween(this.insGetterSetter.thirdPartyObjWatcherAlpha, 0, 10)) {
      errors.push('Alpha param must be between 0 and 1000')
    }
    return errors
  }

  // GETTERS
  get matchingOnePerDsp () {
    if ([this.$APPNEXUS, this.$DBM].indexOf(this.dsp) !== -1) {
      return this.insGetterSetter.matchingLi
    } else if (this.dsp === this.$MEDIAMATH) {
      return this.insGetterSetter.matchingStrat
    } else if (this.dsp === this.$THETRADEDESK) {
      return this.insGetterSetter.matchingAd
    } else if (this.dsp === this.$FACEBOOK) {
      return this.insGetterSetter.matchingAdSet
    } else {
      return this.insGetterSetter.matchingLi
    }
  }

  get isAlgoFieldInDefault (): boolean {
    const conditions = [
      this.activateMatching && this.matchingAreSet(),
      this.activateMaxA && this.insGetterSetter.maxA,
      this.activateMaxCutoff && this.insGetterSetter.maxCutoff,
      this.activateInheritExpectedMargin && !this.inheritFromExpectedMargin,
      this.activateInheritLowest && !this.inheritFromLowestAllowedCPM,
      this.activateInheritHighest && !this.inheritFromHighestAllowedCPM,
      this.activateRecency && this.insGetterSetter.itemRecency,
      this.activateMaxDivMaxMult && (this.insGetterSetter.campaignMaxDiv || this.insGetterSetter.campaignMaxMult)
    ]

    for (let condition of conditions) {
      if (condition) {
        return false
      }
    }
    return true
  }
  get inheritMarginDaily () {
    if (commonUtils.isNotNullOrUndefined(this.insGetterSetter.marginDaily) && this.insGetterSetter.marginDaily !== 0 && typeof this.insGetterSetter.marginDaily === 'number') {
      return Math.round(this.insGetterSetter.marginDaily * 100)
    } else {
      return ''
    }
  }
  set inheritMarginDaily (value: any) {
    this.insGetterSetter.marginDaily = value !== '' && value !== 0 && commonUtils.isNotNullOrUndefined(value)
      ? value / 100
      : value
  }

  get inheritHighestCPM () {
    if (commonUtils.isNotNullOrUndefined(this.insGetterSetter.constraintsMaxCpm) && this.insGetterSetter.constraintsMaxCpm !== 0 && typeof this.insGetterSetter.constraintsMaxCpm === 'number') {
      return this.insGetterSetter.constraintsMaxCpm
    } else {
      return ''
    }
  }
  set inheritHighestCPM (value: any) {
    this.insGetterSetter.constraintsMaxCpm = value
  }

  get inheritLowestCPM () {
    if (commonUtils.isNotNullOrUndefined(this.insGetterSetter.constraintsMinCpm) && this.insGetterSetter.constraintsMinCpm !== 0 && typeof this.insGetterSetter.constraintsMinCpm === 'number') {
      return this.insGetterSetter.constraintsMinCpm
    } else {
      return ''
    }
  }
  set inheritLowestCPM (value: any) {
    this.insGetterSetter.constraintsMinCpm = value
  }

  get getTitleMatchingOne (): string {
    if ([this.$APPNEXUS, this.$DBM].indexOf(this.dsp) !== -1) {
      return 'Matching Li'
    } else if (this.dsp === this.$MEDIAMATH) {
      return 'Matching Strat'
    } else if (this.dsp === this.$THETRADEDESK) {
      return 'Matching Adgroup'
    } else {
      return 'Matching Li'
    }
  }

  // SETTERS
  set matchingOnePerDsp (value) {
    if ([this.$APPNEXUS, this.$DBM].indexOf(this.dsp) !== -1) {
      this.insGetterSetter.matchingLi = value
    } else if (this.dsp === this.$MEDIAMATH) {
      this.insGetterSetter.matchingStrat = value
    } else if (this.dsp === this.$THETRADEDESK) {
      this.insGetterSetter.matchingAd = value
    } else if (this.dsp === this.$FACEBOOK) {
      this.insGetterSetter.matchingAdSet = value
    }
  }

  get inheritFromExpectedMargin (): boolean {
    return this.$store.getters.getInheritFromExpectedMargin
  }

  set inheritFromExpectedMargin (value: boolean) {
    this.$store.commit('setInheritFromExpectedMargin', value)
  }

  get warningAlpha () {
    return this.insGetterSetter.thirdPartyObjWatcherAlpha >= 2
  }

  get hintWarningAlpha () {
    return this.warningAlpha
      ? 'you selected a very high 3rd party alpha value, it might hurt the optimization of the main KPI'
      : null
  }

  // INHERIT WATCHER SYSTEM
  @Watch('inheritFromExpectedMargin')
  onInheritFromExpectedMarginChange () {
    if (this.inheritFromExpectedMargin) {
      this.insGetterSetter.marginDaily = this.insGetterSetter.expectedMargin
    }
    this.launchTimeOutBadge()
  }
  @Watch('insGetterSetter.expectedMargin', { deep: true, immediate: false })
  onExpectedMarginChange () {
    if (this.inheritFromExpectedMargin) {
      this.insGetterSetter.marginDaily = this.insGetterSetter.expectedMargin
    }
  }
  @Watch('inheritFromHighestAllowedCPM')
  onInheritFromHighestAllowedCPMChange () {
    if (this.inheritFromHighestAllowedCPM) {
      this.insGetterSetter.constraintsMaxCpm = this.insGetterSetter.highestAllowedCPM
    }
    this.launchTimeOutBadge()
  }
  @Watch('insGetterSetter.highestAllowedCPM', { deep: true, immediate: false })
  onHighestAllowedCPMChange () {
    if (this.inheritFromHighestAllowedCPM) {
      this.insGetterSetter.constraintsMaxCpm = this.insGetterSetter.highestAllowedCPM
    }
  }
  @Watch('inheritFromLowestAllowedCPM')
  onInheritFromLowestAllowedCPMChange () {
    if (this.inheritFromLowestAllowedCPM) {
      this.insGetterSetter.constraintsMinCpm = this.insGetterSetter.lowestAllowedCPM
    }
    this.launchTimeOutBadge()
  }
  @Watch('insGetterSetter.lowestAllowedCPM', { deep: true, immediate: false })
  onLowestAllowedCPMChange () {
    if (this.inheritFromLowestAllowedCPM) {
      this.insGetterSetter.constraintsMinCpm = this.insGetterSetter.lowestAllowedCPM
    }
  }
  @Watch('lazyMatchingOne')
  onLazyMatchingOneChange (value: string) {
    this.lazyMatchingProcess(value, 'matchingParserLi', 'errorsMatchingLi', 'matchingOnePerDsp')
  }
}

