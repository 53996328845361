
import Vue from 'vue'
import Component from 'vue-class-component'
import * as rules from '../../../../rules/rules'
import BulkEditLine from '../FormFragments/BulkEditLine.vue'
import { instructionsFieldEnabledPerDsp } from '../../../../../config/dspConfig'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    BulkEditLine
  }
})
export default class BulkEditDialog extends Vue {
  @Prop({ required: true, default: function (): Array<any> { return [] } }) selectedInstructions: Array<any>
  @Prop({ required: true }) dsp: string
  @Prop({ required: true, default: false }) isOptiAutoActive: boolean
  @Prop({ required: true, default: false }) isOttoMinVizActive: boolean
  @Prop({ required: true, default: false }) isSurcoucheRatioActive: boolean

  data: {[x: string]: {[y: string]: any}} = {
    isActive: { type: null, value: null },
    kpiCpa: { type: null, value: null },
    maxCpm: { type: null, value: null },
    offset: { type: null, value: null },
    minViz: { type: null, value: null },
    optiRatio: { type: null, value: null }
  }
  rules = rules
  enabledPerDsp: {[x: string]: Array<string>} = instructionsFieldEnabledPerDsp

  // Fields for bulk edit
  fields: {[x: string]: {[y: string]: any}} = {
    booleanFields: [
      { modelKey: 'isActive', customConditions: [], label: 'Is Active', ref: 'bulk-edit-line-is-active' }
    ],
    valueFields: [
      { modelKey: 'kpiCpa', customConditions: [{ getter: 'isOptiAutoActive', expected: false }], label: 'KPI CPA', ref: 'bulk-edit-line-kpi-cpa' },
      { modelKey: 'maxCpm', customConditions: [], label: 'Max Bid', ref: 'bulk-edit-line-max-cpm' },
      { modelKey: 'offset', customConditions: [{ getter: 'isOptiAutoActive', expected: false }], label: 'Offset', ref: 'bulk-edit-line-offset' },
      { modelKey: 'minViz', customConditions: [{ getter: 'isOttoMinVizActive', expected: false }], label: 'Viz Filter', ref: 'bulk-edit-line-min-viz' },
      { modelKey: 'optiRatio', customConditions: [{ getter: 'isSurcoucheRatioActive', expected: true }], label: 'Attribution Ratio', ref: 'bulk-edit-line-line-opti-ratio' }
    ]
  }

  applyCustomConditions (conditions: Array<any>) {
    if (conditions == null || conditions.length === 0) { return true }
    for (let condition of conditions) {
      if (this[condition.getter as keyof this] !== condition.expected) {
        return false
      }
    }
    return true
  }

  emitApply () {
    this.$emit('apply-bulk-edit', this.data)
  }
  emitCancel () {
    this.$emit('cancel-bulk-edit')
  }
  isFieldEnabledPerDsp (field: string): boolean {
    if (Object.keys(this.enabledPerDsp).indexOf(this.dsp) === -1) {
      console.warn(`enabledPerDsp has not a key equal to the dsp ${this.dsp}. No "objective" field will be displayed.`)
      return false
    }

    let enabledPerDsp = this.enabledPerDsp[this.dsp]
    return enabledPerDsp.indexOf(field) !== -1 || (enabledPerDsp.indexOf('*') !== -1 && enabledPerDsp.length === 1)
  }
  resetForm () {
    Object.keys(this.data).forEach(key => {
      this.data[key] = { type: null, value: null }
    })

    this.fields.booleanFields.forEach((line: any) => {
      if (this.$refs[line.ref] != null) {
        const component: BulkEditLine = (this.$refs[line.ref as string] as BulkEditLine[])[0]
        component.resetComponent()
      }
    })
    this.fields.valueFields.forEach((line: any) => {
      if (this.$refs[line.ref] != null) {
        const component: BulkEditLine = (this.$refs[line.ref as string] as BulkEditLine[])[0]
        component.resetComponent()
      }
    })
  }
}
