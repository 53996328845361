
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CheckboxButton extends Vue {
  @Prop({ required: true }) model: boolean
  @Prop({ required: true }) label: String
  @Prop({ required: false, default: false }) loading: boolean
  @Prop({ required: false, default: false }) disabled: boolean
  // Emit fn
  @Prop({ required: false, default: false }) hasToEmitFn: boolean
  // Tooltip
  @Prop({ required: false, default: false }) withTooltip: boolean
  @Prop({ required: false, default: '' }) tooltipMessage: String
  @Prop({ required: false, default: null }) tooltipPlacement: String
  // Design
  @Prop({ required: false, default: false }) flat: boolean

  value: boolean = false

  mounted () {
    this.setValue()
  }
  setValue () {
    this.value = this.model
  }

  fn () {
    this.$emit('fn')
  }

  @Watch('model')
  onModelChange () {
    this.setValue()
  }
}
