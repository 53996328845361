import {
  Instruction,
  InstructionAppnexus,
  InstructionDbm,
  InstructionDsp,
  InstructionMediamath,
  InstructionTheTradeDesk,
  InstructionYoutube, MaxFrequency, TypeOfBudgetInstruction
} from '../../types/instruction_type'
import BriefModel from '../../src/models/Briefs/BriefModel'
import { isAppx, isBeeswax, isDbm, isMediamath, isPeriod, isTheTradeDesk, isYoutube } from './isIns'
import { ConversionFunnelInstruction } from '../../types/brief_type'
import { BudgetType, KpiValue } from '../../types/brief_enum'
import VueNestedObject from '../../src/models/BaseModel/VueNestedObject'
import { deepCopy } from '../commonUtils'
import { getAllMethodOfClassStartingBy } from '../classUtils'
import ConstraintsModel from '@/models/Briefs/ConstraintsModel'

function _commonPrefillMaxFrequency (brief: BriefModel, instruction: Instruction) {
  const frequencyMode = brief.constraints.overwriteFrequencyMode

  // first, we check if there is a value in contraints.overwrite_frequency_mode
  if (frequencyMode) {
    if (frequencyMode === 'allowed_but_no_constraints') {
      instruction.max_frequency = {}
      instruction.overwrite_frequency = true
    } else if (frequencyMode === 'allowed_with_constraints') {
      instruction.max_frequency = {}
      instruction.overwrite_frequency = true
    } else if (frequencyMode === 'not_allowed') {
      instruction.max_frequency = {}
      instruction.overwrite_frequency = false
    }
  } else {
    // otherwise, the brief is in the old mode before the update
    if (!brief.constraints.allowFrequencyCap) {
      instruction.max_frequency = {}
      instruction.overwrite_frequency = true
    }
  }
}

/**
 * Prefill a instruction with a brief from the customer user interface
 * https://docs.google.com/spreadsheets/d/1Mv1VVaIpvYH-xgk_ZT44fcoVVU6tEb2_63wRtiXouU8/edit#gid=1234943145
 */
export default class BriefPrefiller {
  vueNestedObject = new VueNestedObject()

  processPrefillInstructionWithBrief (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp, funnel: ConversionFunnelInstruction): Instruction {
    let allFunctionName = this._getAllFunctionName()

    for (let functionName of allFunctionName) {
      this[functionName](brief, instruction, dsp, funnel)
    }
    return instruction
  }

  prefillMaxFrequency (brief: BriefModel, instruction: Instruction) {
    _commonPrefillMaxFrequency(brief, instruction)
  }

  prefillTargetFrequency (brief: BriefModel, instruction: Instruction) {
    instruction.overwrite_frequency_target = brief.KPI === 'reach_and_frequency' && brief.constraints.allowFrequencyTarget
  }
  prefillRemoveDeprecatedUrl (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isDbm(instruction, dsp) || isYoutube(instruction, dsp)) {
      instruction.automatically_remove_deprecated_url = brief.constraints.automaticallyRemoveDeprecatedUrl
    }
  }

  BudgetRepartition = {
    prefill (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
      /* eslint-disable padded-blocks */
      if (isAppx(instruction, dsp)) {
        this.prefillBudgetRepartitionAppx(brief, instruction)
      } else if (isMediamath(instruction, dsp)) {
        this.prefillBudgetRepartitionMediamath(brief, instruction)
      } else if (isTheTradeDesk(instruction, dsp)) {
        this.prefillBudgetRepartitionTheTradeDesk(brief, instruction)

      } else if (isYoutube(instruction, dsp)) {
        this.prefillBudgetRepartitionYoutube(brief, instruction)
      } else if (isBeeswax(instruction, dsp)) {
        //

      } else if (isDbm(instruction, dsp)) {
        this.prefillBudgetRepartitionDbm(brief, instruction)
      }
      /* eslint-enable padded-blocks */
    },
    prefillBudgetRepartitionAppx (brief: BriefModel, instruction: InstructionAppnexus) {
      if (brief.constraints.budgetRepartition) {
        instruction.remove_daily_budget_global = true
        instruction.remove_daily_budget_campaigns_only = false
      } else {
        instruction.remove_daily_budget_global = false
        instruction.remove_daily_budget_campaigns_only = false
      }
    },
    prefillBudgetRepartitionDbm (brief: BriefModel, instruction: InstructionDbm) {
      instruction.overwrite_daily_budget_li = !!brief.constraints.budgetRepartition
      return instruction
    },
    prefillBudgetRepartitionYoutube (brief: BriefModel, instruction: InstructionYoutube) {
      instruction.overwrite_li_budget = !!brief.constraints.budgetRepartition
    },
    prefillBudgetRepartitionMediamath (brief: BriefModel, instruction: InstructionMediamath) {
      instruction.remove_budget_strat_imp = !!brief.constraints.budgetRepartition
      instruction.remove_budget_strat_money = !!brief.constraints.budgetRepartition
    },
    prefillBudgetRepartitionTheTradeDesk (brief: BriefModel, instruction: InstructionTheTradeDesk) {
      instruction.overwrite_daily_budget_impressions_adgroup = !!brief.constraints.budgetRepartition
      instruction.overwrite_daily_budget_money_adgroup = !!brief.constraints.budgetRepartition
    }
  }

  prefillBudgetRepartition (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    this.BudgetRepartition.prefill(brief, instruction, dsp)
  }

  prefillCreativeActivity (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isAppx(instruction, dsp) || isDbm(instruction, dsp)) {
      instruction.overwrite_creative_selection = brief.constraints.creativeActivity
    }
  }

  prefillFoldPosition (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isAppx(instruction, dsp) || isDbm(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
      instruction.remove_fold_position = !!brief.constraints.foldPosition
    }
  }

  FrequencyCap = {
    prefill (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
      const frequencyMode = brief.constraints.overwriteFrequencyMode

      const defaultPrefill = (ins: Instruction, br: BriefModel) => {
        if (!ins.max_frequency) {
          ins.max_frequency = {}
        }

        if (br.constraints.frequencyCapAmount && !isNaN(br.constraints.frequencyCapAmount as number)) {
          ins.max_frequency.amount = br.constraints.frequencyCapAmount as number
        }

        if (br.constraints.frequencyCapExposure && !isNaN(br.constraints.frequencyCapExposure as number)) {
          ins.max_frequency.exposures = br.constraints.frequencyCapExposure as number
        }
        if (isPeriod(br.constraints.frequencyCapPeriod)) {
          ins.max_frequency.period = br.constraints.frequencyCapPeriod
        }
      }

      if (frequencyMode) {
        if (frequencyMode === 'allowed_with_constraints') {
          if (brief.constraints.frequencyCapAmount) {
            defaultPrefill(instruction, brief)
          }
        } else if (frequencyMode === 'allowed_but_no_constraints') {
          if (!instruction.max_frequency) {
            instruction.max_frequency = {}
          }
        }
      } else {
        // we are in a brief with the old mode (before the update of the ticket 1774
        defaultPrefill(instruction, brief)
      }
    }
  }

  prefillFrequencyCap (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    this.FrequencyCap.prefill(brief, instruction, dsp)
  }

  FrequencyTarget = {
    prefill (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
      const defaultPrefill = (ins: Instruction, br: BriefModel) => {
        if (!ins.target_frequency) {
          ins.target_frequency = {}
        }

        if (br.constraints.frequencyTargetAmount && !isNaN(br.constraints.frequencyTargetAmount as number)) {
          ins.target_frequency.amount = br.constraints.frequencyTargetAmount as number
        }

        if (br.constraints.frequencyTargetExposure && !isNaN(br.constraints.frequencyTargetExposure as number)) {
          ins.target_frequency.exposures = br.constraints.frequencyTargetExposure as number
        }
        if (isPeriod(br.constraints.frequencyTargetPeriod)) {
          ins.target_frequency.period = br.constraints.frequencyTargetPeriod
        }
      }
      if (!brief.constraints.allowFrequencyTarget) {
        instruction.target_frequency = {}
        instruction.overwrite_frequency_target = brief.KPI === 'reach_and_frequency'
      } else {
        defaultPrefill(instruction, brief)
      }
    }
  }

  prefillFrequencyTarget (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    this.FrequencyTarget.prefill(brief, instruction, dsp)
  }

  prefillBidAdjustmentsOnDevice (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isYoutube(instruction, dsp)) {
      instruction.overwrite_bid_adjustments_on_device = brief.constraints.overwriteBidAdjustmentsOnDevice
    }
  }

  prefillTimeParting (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isAppx(instruction, dsp) || isYoutube(instruction, dsp)) {
      instruction.remove_time_parting = !!brief.constraints.timeParting
    } else if (isDbm(instruction, dsp)) {
      instruction.keep_timeparting = !brief.constraints.timeParting
    } else if (isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
      instruction.remove_timeparting = brief.constraints.timeParting
    }
  }

  prefillViewabilitySettings (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isAppx(instruction, dsp)) {
      instruction.remove_min_viz = brief.constraints.viewabilitySettings
    } else if (isDbm(instruction, dsp)) {
      instruction.keep_min_viz = !brief.constraints.viewabilitySettings
    } else if (isTheTradeDesk(instruction, dsp)) {
      instruction.overwrite_viewability = brief.constraints.viewabilitySettings
    }
  }

  prefillConversionFunnel (
    brief: BriefModel,
    instruction: Instruction,
    dsp: InstructionDsp,
    funnel: ConversionFunnelInstruction) {
    const kpi = brief.KPI
    if (kpi === 'other') {
      return
    }
    if (funnel && typeof funnel === 'object') {
      instruction.true_conversion_funnel_pixel_ids = deepCopy(funnel)
    }

    instruction.true_KPI_to_optimize = kpi
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'true_KPI_to_optimize')
    this.vueNestedObject.reactiveSet(instruction.obj_watcher.true_KPI_to_optimize, 'KPI', kpi)
  }

  prefillBudgetToSaturateInPriority (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    let budget: TypeOfBudgetInstruction
    if (brief.budgetToSaturateInPriority === BudgetType.IMPRESSION) {
      budget = 'imp'
    } else if (brief.budgetToSaturateInPriority === BudgetType.MONETARY) {
      budget = 'money'
    } else {
      console.warn('[BriefPrefiller.ts] Not managed budget')
      return
    }

    if (isBeeswax(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'saturation')
      this.vueNestedObject.reactiveSet(instruction.obj_watcher.saturation, 'budget_type', budget)
    }
  }

  prefillMinMargin (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    let minMargin = this._canBeDivide(brief.minMargin) ? Number(brief.minMargin) / 100 : brief.minMargin

    if (!minMargin) {
      return
    }

    if (isBeeswax(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp) || isAppx(instruction, dsp) || isDbm(instruction, dsp)) {
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'margin')
      this.vueNestedObject.reactiveSet(instruction.obj_watcher.margin, 'value', minMargin)

      this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'constraints_io')
      this.vueNestedObject.reactiveSet(instruction.constraints_io, 'margin_daily', minMargin)
    }
  }

  prefillRevCpm (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    let revCpm = brief.revCPM

    if (!revCpm) {
      return
    }

    if (isBeeswax(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'constraints_io')
      this.vueNestedObject.reactiveSet(instruction.constraints_io, 'rev_CPM', revCpm)
    }
  }

  prefillBoostTarget (brief: BriefModel, instruction: Instruction) {
    let boostTarget = brief.boostTarget
    let kpi = brief.KPI
    if (!boostTarget) {
      return
    }
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'true_KPI_to_optimize')
    let boostTargetValue = [KpiValue.CTR, KpiValue.VTR].includes(kpi) && this._canBeDivide(boostTarget)
      ? boostTarget / 100
      : boostTarget
    this.vueNestedObject.reactiveSet(instruction.obj_watcher.true_KPI_to_optimize, 'value', boostTargetValue)
  }

  prefillConversionMeasurementTool (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (brief.conversionMeasurementTool === 'dsp') {
      instruction.true_conv_measurement_tool = dsp
    } else if (brief.conversionMeasurementTool !== 'other') {
      instruction.true_conv_measurement_tool = brief.conversionMeasurementTool
    }
  }

  prefillMinCpm (brief: BriefModel, instruction: Instruction) {
    let minCpm = brief.minCpm

    if (!minCpm) {
      return
    }
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'min_CPM')
    this.vueNestedObject.reactiveSet(instruction.obj_watcher.min_CPM, 'value', minCpm)
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'constraints_io')
    this.vueNestedObject.reactiveSet(instruction.constraints_io, 'min_CPM', minCpm)
  }

  prefillMaxCpm (brief: BriefModel, instruction: Instruction) {
    let maxCpm = brief.maxCpm

    if (!maxCpm) {
      return
    }
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'max_CPM')
    this.vueNestedObject.reactiveSet(instruction.obj_watcher.max_CPM, 'value', maxCpm)
    this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'constraints_io')
    this.vueNestedObject.reactiveSet(instruction.constraints_io, 'max_CPM', maxCpm)
  }

  prefillViewRate (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    let viewRate = this._canBeDivide(brief?.constraints?.viewRate) ? Number(brief?.constraints?.viewRate) / 100 : brief?.constraints?.viewRate

    if (!viewRate) {
      return
    }

    if (isBeeswax(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp) ||
      isAppx(instruction, dsp) || isDbm(instruction, dsp)) {
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction, 'obj_watcher')
      this.vueNestedObject.createObjectIfNotSetWithItem(instruction.obj_watcher, 'min_viz')
      this.vueNestedObject.reactiveSet(instruction.obj_watcher.min_viz, 'value', viewRate as number)
    }
  }

  prefillUnknownDemographic (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
    if (isDbm(instruction, dsp) || isYoutube(instruction, dsp)) {
      instruction.overwrite_unknown_demographic = brief.constraints.allowUnknownDemographic
    }
  }

  _canBeDivide (n: any) {
    return !isNaN(n) && n !== 0
  }

  prefillRevenueType (brief: BriefModel, instruction: Instruction) {
    if (brief != null && 'revenueType' in brief) {
      instruction.revenue_type = brief.revenueType !== 'other' ? brief.revenueType : null
    } else if (instruction?.obj_watcher?.saturation?.budget_type != null) {
      instruction.revenue_type = instruction?.obj_watcher?.saturation?.budget_type === 'imp' ? 'rev_cpm' : 'cost_plus'
    }
  }

  _getAllFunctionName () {
    return getAllMethodOfClassStartingBy<BriefPrefiller>(this, 'prefill')
  }
}
