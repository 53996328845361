<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Explode
    </v-card-title>
    <v-card-text>
      {{ messageOkExplode }}
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" :loading="isProcessingExplode" @click="emitExplode">
        Yes
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="emitNoExplode">
        No, just deactivate {{ step === 'opti_auto' ? 'Otto' : 'Otto min viz' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ExplodeDialog',
  props: {
    isProcessingExplode: {
      type: Boolean
    },
    step: {
      type: String,
      default: 'opti_auto'
    },
    hasOttoMinViz: {
      type: Boolean,
      default: false
    },
    hasOptiAuto: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {}
  },
  methods: {
    emitExplode () {
      this.$emit('explode')
    },
    emitNoExplode () {
      this.$emit('no-explode')
    }
  },
  computed: {
    messageOkExplode () {
      if (this.step === 'opti_auto') {
        if (this.hasOttoMinViz) {
          return 'Do you want to create instructions based on the last pushed A/B params?'
        } else {
          return 'Do you want to create instructions based on the last pushed A/B params and Viz filter?'
        }
      } else if (this.step === 'otto_min_viz') {
        if (this.hasOptiAuto) {
          return 'Do you want to create instructions based on the Viz filter?'
        } else {
          return 'Do you want to create instructions based on the last pushed A/B params & Viz filter?'
        }
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
