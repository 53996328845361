<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <LabelValue
          :label="label"
          :value="value"
          :ellipsis="ellipsis"
          :long-label="longLabel"
          :little-label="littleLabel"
          :ioFormTopbar="ioFormTopbar"
          :class="{ 'show-red-text': attention }"
        >
        </LabelValue>
      </div>
    </template>
    <span>
      {{ tooltipText }}
    </span>
  </v-tooltip>
</template>

<script>
import LabelValue from './LabelValue'

export default {
  name: 'LabelValueToolTip',
  components: {
    LabelValue
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true,
      default: 'NC'
    },
    littleLabel: {
      type: Boolean,
      default: false
    },
    longLabel: {
      type: Boolean,
      default: false
    },
    ellipsis: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      required: true
    },
    ioFormTopbar: {
      type: Boolean,
      required: false,
      default: false
    },
    attention: {
      type: Boolean,
      required: false,
      default: false
    }
  }

}
</script>

<style scoped>
.show-red-text {
  color: red;
  font-weight: bold;
}

</style>
