import { decomposeIoIdBeeswax, recomposeIoIdBeeswax } from '../../utils/instructionsUtils'
import BriefModel from '@/models/Briefs/BriefModel'

export const getTraderBriefMixin = {
  data: function () {
    return {
      /**
       * @type {BriefModel}
       */
      brief: null,
      /**
       * @type {ConversionFunnelInstruction}
       */
      funnel: null,
      loadingTraderBrief: false,
      loadingAcknowledgments: false,
      /**
       * @type {AcknowledgmentInfo[]}
       */
      acknowledgments: []
    }
  },
  methods: {
    async getTraderBrief (clientId, externalId, dsp) {
      if (dsp === this.$BEESWAX) {
        externalId = recomposeIoIdBeeswax(clientId, externalId)
      }

      this.loadingTraderBrief = true
      const response = await this.$apiCaller.getBriefOfExternalId(externalId, dsp)

      if (this.$apiCaller.isResponseError(response, false)) {
        console.warn('Error when calling brief')
      } else {
        if (response.data && response.data.length) {
          let activeBriefs = response.data.filter((item) => {
            return item.status === 'active'
          })
          if (activeBriefs.length) {
            this.brief = new BriefModel(null, activeBriefs[0])
            let funnelArgs = activeBriefs[0].funnel
            if (Array.isArray(funnelArgs)) {
              this.funnel = this.adaptFunnel(activeBriefs[0].funnel, dsp, clientId)
            }
          }
        }
      }
      this.loadingTraderBrief = false
    },
    async getAcknowledgmentsOfBriefId (briefId) {
      if (!briefId || isNaN(briefId)) {
        return
      }
      this.loadingAcknowledgments = true
      const response = await this.$apiCaller.getAcknowledgments(briefId)

      if (this.$apiCaller.isResponseError(response, false)) {
        console.warn('Error when calling acknowledgments')
      } else {
        if (response.data && response.data.length) {
          this.acknowledgments = response.data
        }
      }
      this.loadingAcknowledgments = false
    },
    /**
     * @param funnel {FunnelStepArgs}
     * @param dsp {String}
     * @param clientId {String|Number}
     * @returns {ConversionFunnelInstruction|{}}
     */
    adaptFunnel (funnel, dsp = null, clientId = null) {
      let objFunnel = {}
      for (let i in funnel) {
        let key = Number(i) + 1
        objFunnel[key] = funnel[i].map((item) => {
          let pixelId = item.external_id
          if (dsp === this.$BEESWAX) {
            pixelId = decomposeIoIdBeeswax(clientId, pixelId)
          }
          return {
            type: item.type,
            id: pixelId,
            origin: 'dsp'
          }
        })
      }
      return objFunnel
    }
  }
}
