
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { CheckPreMepResult } from '../../../types/instruction_type'
import ReasonMessageList from '@/components/CheckPreMepComponents/ReasonMessageList.vue'

@Component({
  components: {
    ReasonMessageList
  }
})
export default class CheckPreMepCard extends Vue {
  @Prop()
  checkPreMepResult: CheckPreMepResult

  @Prop()
  status: string

  blockingMessage = 'Check premep result not available → We could not determine the optimisability of this item, please raise a ticket in Jira.'

  getDate () {
    return this.checkPreMepResult
      ? new Date(this.checkPreMepResult.computation_date)
      : null
  }
}
