
class SyntaxErrorMatching extends Error {
  constructor (...args) {
    super(...args)
    Error.captureStackTrace(this, SyntaxErrorMatching)
  }
}

function MatchingParser () {
  this.separator = ':'
  this.errors = []
  this.regexValidator = /(\d)+(\s*:\s*)(\d)+/
  this.tokens = []
  this.startChar = '{'
  this.endChar = '}'
  this.__allowString = false
  this.regexValidatorWithStringAllowed = /^"([A-Za-z0-9])+"(\s*:\s*)"([A-Za-z0-9])+"$/

  this.allowString = function (bool) {
    if (typeof bool !== 'boolean') {
      throw new TypeError('the value of bool must be a boolean ! ' + typeof bool + ' received.')
    }

    this.__allowString = bool
  }

  this.isStringAllowed = function () {
    return this.__allowString
  }

  this.__deleteError = function (lineNumber) {
    if (this.errors[lineNumber] !== undefined) {
      delete this.errors[lineNumber]
    }
  }

  this.__deleteLine = function (lineNumber) {
    if (this.tokens[lineNumber] !== undefined) {
      delete this.tokens[lineNumber]
    }
  }

  this.__cleanErrors = function () {
    this.errors = []
  }

  this.__cleanLines = function () {
    this.tokens = []
  }

  this.__tokenize = function (line, lineNumber) {
    let token = []

    try {
      token = this.__validateLine(line)
    } catch (e) {
      let error = {
        type: e.constructor.name,
        message: e.message,
        line: Number(lineNumber) + 1
      }

      this.errors[lineNumber] = error
      return false
    }

    for (let i in token) {
      token[i] = token[i].trim()
    }

    this.tokens[lineNumber] = `${token[0]}:${token[1]}`
    // remove the line errors
    this.__deleteError(lineNumber)
  }

  this.__validateLine = function (line) {
    let token = line.split(this.separator)

    // not necessary with the regex test, but allow the parser to return more informations
    // about parsing errors
    if (this.isStringAllowed()) {
      if (this.regexValidatorWithStringAllowed.test(line) === false) {
        throw new SyntaxErrorMatching('Syntax error')
      }
    } else {
      for (let i in token) {
        if (isNaN(token[i])) {
          throw new SyntaxErrorMatching('Token must be a numeric value')
        }
      }

      if (this.regexValidator.test(line) === false) {
        throw new SyntaxErrorMatching('Syntax error')
      }
    }

    if (token.length !== 2) {
      throw new SyntaxErrorMatching('Must have only 2 token per line')
    }

    return token
  }

  this.__validateSyntax = function (toParse) {
    this.__cleanErrors()
    this.__cleanLines()

    const lines = toParse.split('\n')
    let hasError = false
    for (let i in lines) {
      if (lines[i].trim() === '') {
        this.__deleteLine(i)
        this.__deleteError(i)
        continue
      }
      if (this.__tokenize(lines[i], i) === false) {
        hasError = true
      }
    }

    return !hasError
  }

  this.__createParsedData = function () {
    let parsedData = this.startChar
    parsedData += this.tokens.join(',')
    return parsedData + this.endChar
  }

  this.parseString = function (toParse) {
    if (typeof toParse !== 'string') {
      throw TypeError('The type of the parsed value must be a string, ' + typeof toParse + ' received')
    }

    if (toParse.trim() === '') {
      return ''
    }

    if (this.__validateSyntax(toParse) === true) {
      return this.__createParsedData()
    }

    return false
  }

  this.getErrors = function () {
    let verboseErrors = []

    for (let i in this.errors) {
      let cError = this.errors[i]
      let strError = `${cError.type} : "${cError.message}." at line ${cError.line}`
      verboseErrors.push(strError)
    }
    return verboseErrors
  }
}

function ReverseMatchingParser () {
  this.originalString = ''
  this.reverseParsedString = ''

  this.reverseParse = function (toReverse) {
    if (!toReverse || toReverse.trim() === '') {
      return ''
    }

    this.originalString = toReverse
    toReverse = toReverse.replace('{', '').replace('}', '')
    let exploded = toReverse.split(',')
    this.reverseParsedString = exploded.join('\n')

    return this.reverseParsedString
  }
}

export {
  MatchingParser,
  SyntaxErrorMatching,
  ReverseMatchingParser
}
