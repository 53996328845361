
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import {
  FormTitle
} from '../../FormFragments'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import HelpTooltip from '@/components/Common/HelpTooltip.vue'
import BaseWarningDialog from '@/components/Common/BaseWarningDialog.vue'
import { WaterfallPriority } from '../../../../../../types/instruction_type'
import { RevenueType } from '../../../../../../types/brief_enum'

@Component({
  components: {
    HelpTooltip,
    FormTitle,
    BaseWarningDialog
  }
})
export default class WaterfallManagement extends Vue {
  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter

  dialogWarning = false

  helpTooltipOttoFirstPriority = `
    With delivery first, Otto will ensure the <strong>full delivery of the budget</strong>.
    With performance first, Otto will ensure that the target KPI is reached, <strong>regardless of the spending.</strong>
  `

  helpTooltipSelect = `
    <strong>Rolling period</strong> during which the rev KPI value should stay below the KPI target
    defined in the surcouche.
  `

  itemsKpiRollingPeriod: TextValue[] = [
    {
      text: 'Billing',
      value: 'billing'
    },
    {
      text: 'Daily',
      value: 'daily'
    }
  ]

  warningList: WarningList = { names: [], errors: [] }

  warningPerfFirstContent = `
  With this option, Otto will prioritize reaching the KPI target <strong>above anything else</strong>. This optimization mode is likely to generate <strong>severe underpacing</strong>.
  <a href="https://scibids-k.atlassian.net/wiki/x/AQACTg" target="_blank" rel="nofollow noopener noreferrer">Read the documentation</a> to make sure your IO is performance first.
  `

  messageTooltipPerfFirst = 'Perf first can not be activated when there is a CPM constraint or a Rev_CPM revenue type.'

  updateWaterfallPriority (newValue: WaterfallPriority) {
    if (newValue === 'perf_first') {
      this.warningList.names.push('warningPerfFirst')
      this.warningList.errors.push(this.warningPerfFirstContent)
      this.dialogWarning = true
    } else { // newValue === 'delivery_first'
      this.insGetterSetter.waterfallPriority = newValue
    }
    // in any case, when changing the perf_first value, we reset kpirollingPeriod to null
    this.insGetterSetter.kpiRollingPeriod = null
  }

  resetWaterfallPriorityAndWarningList () {
    this.insGetterSetter.waterfallPriority = 'delivery_first'
    this.closeWarningDialog()
  }

  setWaterfallPriorityToPerfFirst () {
    this.insGetterSetter.waterfallPriority = 'perf_first'
    this.closeWarningDialog()
  }

  closeWarningDialog () {
    this.dialogWarning = false
    this.warningList = { names: [], errors: [] }
  }

  errorMessageKpiRolling () {
    const errors = []
    if (this.insGetterSetter.waterfallPriority === 'perf_first' && !this.insGetterSetter.kpiRollingPeriod) {
      errors.push('You have to fill KPI Rolling Period if you want to use performance first.')
    }
    return errors
  }

  isDisabledPerfFirst (): boolean {
    return !!(this.insGetterSetter.lowestAllowedCPM || this.insGetterSetter.highestAllowedCPM || this.insGetterSetter.revenueType === 'rev_cpm')
  }

  get displayRollingPeriod () {
    return this.insGetterSetter.waterfallPriority === 'perf_first'
  }
}
