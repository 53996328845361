import { $APPNEXUS, $BEESWAX, $MEDIAMATH, $THETRADEDESK, $YOUTUBE, $DBM, $FACEBOOK } from '../config/dspConfig'

function appnexusUrl (instructions) {
  return `https://console.appnexus.com/buyside/insertion-order:${instructions.io}/show`
}

function dbmUrl (instructions, url) {
  return `https://displayvideo.google.com/#ng_nav/p/${instructions.partner_id}/a/${instructions.advertiser_id}/c/${url}/io/${instructions.io}/lis`
}

function thetradedeskUrl (instructions) {
  return `https://desk.thetradedesk.com/Campaigns/Detail/${instructions.io}`
}

function mediamathUrl (instructions) {
  return `https://t1.mediamath.com/app/#campaign/edit/${instructions.io}/details/general`
}

function beeswaxUrl (instructions) {
  return `https://playdigo.beeswax.com/advertisers/${instructions.advertiser_id}/campaigns/${instructions.io}/line_items`
}

function facebookUrl (instructions) {
  return `https://business.facebook.com/adsmanager/manage/all?act=${instructions.io}`
}

function getUrlPerDsp (instructions, dsp, url = null) {
  switch (dsp) {
    case $APPNEXUS:
      return appnexusUrl(instructions)
    case $BEESWAX:
      return beeswaxUrl(instructions)
    case $THETRADEDESK:
      return thetradedeskUrl(instructions)
    case $DBM:
      return dbmUrl(instructions, url)
    case $MEDIAMATH:
      return mediamathUrl(instructions)
    case $YOUTUBE:
      return dbmUrl(instructions)
    case $FACEBOOK:
      return facebookUrl(instructions)
    default:
      console.warn(`Not implemented for dsp : ${dsp}`)
      return ''
  }
}

export {
  appnexusUrl,
  dbmUrl,
  thetradedeskUrl,
  mediamathUrl,
  beeswaxUrl,
  facebookUrl,
  getUrlPerDsp
}
