import { $APPNEXUS, $MEDIAMATH, $DBM, $THETRADEDESK, $YOUTUBE, $BEESWAX } from '../../config/dspConfig'
import { RulesChecker } from './RulesChecker'
import { VALIDATED } from '../../store'
import { KpiValue } from '../../types/brief_enum'
import { htmlEntities } from '../commonUtils'

class WarningsChecker extends RulesChecker {
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningKpiToOptimizeOffset (editedItem, instructionContainer, dsp) {
    if ([$APPNEXUS, $MEDIAMATH, $DBM, $THETRADEDESK, $BEESWAX].indexOf(dsp) === -1) {
      return false
    }
    let notValidOffset = this.getInstructionOffsetNotValid(instructionContainer)
    this.notValidOffset = notValidOffset
    if (['CTR', 'VTR'].includes(editedItem.true_KPI_to_optimize) && (editedItem.opti_auto === false && notValidOffset.length !== 0)) {
      return `Warning, you selected <strong>${htmlEntities(editedItem.true_KPI_to_optimize)}</strong> without entering an <strong>Offset</strong> nor activating <strong>Otto</strong>, please validate your choice.`
    }

    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningMaxAOtto (editedItem, instructionContainer, dsp) {
    if ([$APPNEXUS, $MEDIAMATH, $DBM, $BEESWAX, $YOUTUBE, $THETRADEDESK].indexOf(dsp) === -1) {
      return false
    }
    if (this.issetInObject(editedItem, 'constraints_io.max_A') && !editedItem.opti_auto) {
      return `You entered a <strong>max A</strong> value without activating <strong>Otto</strong>, please notice that <strong>max A</strong> won't be taken into account by the algo.`
    }

    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningMaxCutoffKpiToOptimize (editedItem, instructionContainer, dsp) {
    if ([$APPNEXUS, $MEDIAMATH, $DBM, $BEESWAX, $YOUTUBE, $THETRADEDESK].indexOf(dsp) === -1) {
      return false
    }
    if (this.issetInObject(editedItem, 'constraints_io.max_cutoff') && editedItem.true_KPI_to_optimize === 'CPA') {
      return `<strong>Max cutoff</strong> activated with a <strong>CPA</strong> KPI - beware, your performance may be damaged`
    }

    return false
  }

  /**
   * @param instructionContainer {Instruction[]}
   * @return {Instruction[]}
   */
  getInstructionOffsetNotValid (instructionContainer) {
    let notValidOffset = []

    for (let i in instructionContainer) {
      if (!instructionContainer.hasOwnProperty(i)) {
        continue
      }
      if (!this.issetInObject(instructionContainer[i], 'objective.offset')) {
        notValidOffset.push(instructionContainer[i].id)
      }
    }

    return notValidOffset
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningForceMinBudget (editedItem, instructionContainer, dsp) {
    if (this.issetInObject(editedItem, 'constraints_io.force_min_budget') && editedItem.constraints_io.force_min_budget) {
      return `You've checked the <strong>force min budget LI</strong> as an <em>Advanced Constraint</em>,
              beware that it's a tricky option that may hurt the performances`
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningBaseline (editedItem, instructionContainer, dsp) {
    if (typeof editedItem.baseline === 'object' && editedItem.baseline.type === 'before_after' &&
      (!editedItem.overwrite_frequency || editedItem.max_frequency.period === 'Lifetime')) {
      return 'it seems the evaluation method is a before/after and we\'re not allowed to overwrite frequency/ there is a lifetime constraint. ' +
        'Please ensure we will not lose the before/after by design because of this restrictive frequency constraint'
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningOttoColdStart (editedItem, instructionContainer, dsp) {
    const kpiToOptimize = editedItem && editedItem.true_KPI_to_optimize ? editedItem.true_KPI_to_optimize : null
    const budgetType = editedItem && editedItem.obj_watcher && editedItem.obj_watcher.saturation &&
    editedItem.obj_watcher.saturation.budget_type
      ? editedItem.obj_watcher.saturation.budget_type : null
    const revenueType = editedItem.revenue_type

    if (editedItem.status !== 'VALIDATED' &&
        editedItem.opti_auto &&
      (['CTR', 'VTR'].includes(kpiToOptimize) || budgetType === 'imp' || revenueType === 'rev_cpm')) {
      const methodologyLink = 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/1243447298/Manage+A+B+in+the+surcouche'
      return `Beware that activating Otto from day one on a campaign that is either "rev_cpm" or with  "CTR/VTR" as KPI is not recommended, computing A/B using this
 ${this.generateATag(methodologyLink, 'methodology')} is a best practice`
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningOttoColdStartAndPerfFirst (editedItem, instructionContainer, dsp) {
    if (editedItem.waterfall_priority === 'perf_first' && editedItem.opti_auto) {
      const methodologyLink = 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/1308753921/Otto+Perf+First+-+Definition+Set-up+draft#How-to-set-up-a-Performance-first-campaign-is-the-surcouche%3F-(draft)'
      return `
      Beware that activating otto from day one on a Performance First campaign is not recommended. Computing the A value using
      ${this.generateATag(methodologyLink, 'this methodology')} is a best practice.
      `
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningRevenueTypeConstraintsPerfFirst (editedItem, instructionContainer, dsp) {
    const highestAllowedCPM = this.issetInObject(editedItem, 'obj_watcher.max_CPM.value') ? editedItem.obj_watcher.max_CPM.value : null
    const lowestAllowedCPM = this.issetInObject(editedItem, 'obj_watcher.min_CPM.value') ? editedItem.obj_watcher.min_CPM.value : null
    const revenueType = editedItem.revenue_type
    if (editedItem.waterfall_priority === 'perf_first' &&
      (highestAllowedCPM || lowestAllowedCPM || (revenueType === 'rev_cpm'))) {
      const documentationLink = 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/1308753921/Performance+First+Definition+Set-up'
      return `
      Otto perf_first is not compatible with <strong>CPM constraints</strong> or a <strong>Rev_CPM business model</strong>.
      CPM constraints need to be removed & the Revenue type must be cost+ to proceed.
      ${this.generateATag(documentationLink, 'Read the documentation')} for more information on Performance First.
      `
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningMinVizFilter (editedItem, instructionContainer, dsp) {
    if ([$APPNEXUS, $DBM, $THETRADEDESK].indexOf(dsp) === -1) {
      return false
    }
    let vizFilter = true
    for (const instru of instructionContainer) {
      if (dsp === $THETRADEDESK) {
        if (!this.issetInObject(instru, 'objective.min_viz') && !this.issetInObject(instru, 'objective.min_video_viz')) {
          vizFilter = false
          break
        }
      } else {
        if (!this.issetInObject(instru, 'objective.min_viz')) {
          vizFilter = false
          break
        }
      }
    }
    let overwrite = false
    switch (dsp) {
      case $DBM:
        overwrite = !editedItem.keep_min_viz
        break
      case $THETRADEDESK:
        overwrite = editedItem.overwrite_viewability
        break
      case $APPNEXUS:
        overwrite = editedItem.remove_min_viz
        break
    }
    const errorMsg = 'There is a viewability constraint in the brief section, but no viz filter set up at the instructions level.'
    const errorMsgNoOttoMinViz = 'Warning: This IO has a viewability constraint but Otto min viz is not activated, make sure the setup is correct before saving.'
    if (this.issetInObject(editedItem, 'obj_watcher.min_viz.value') && overwrite && !editedItem.otto_min_viz) {
      return errorMsgNoOttoMinViz
    }
    if (this.issetInObject(editedItem, 'obj_watcher.min_viz.value') && overwrite && !editedItem.otto_min_viz && !vizFilter) {
      return errorMsg
    }
    return false
  }
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningForceAsapPacingMoney (editedItem, instructionContainer, dsp) {
    if (![$MEDIAMATH].includes(dsp)) {
      return false
    }
    if (editedItem.force_pacing_asap_money) {
      return 'You Activated the <strong>"force asap pacing money"</strong> feature. Beware that doing so may harm MM’s bidder,' +
        ' only use it when you’re facing underdelivery or when you have a retargeting campaign'
    }
    return false
  }
  generateATag (href, content) {
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${content}</a>`
  }

  getLastNotValidOffset () {
    return this.notValidOffset !== undefined ? this.notValidOffset : undefined
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningYoutubeForActionOptiAuto (editedItem, instructionContainer, dsp) {
    if (editedItem.true_KPI_to_optimize === KpiValue.CPA && dsp === $YOUTUBE && editedItem.opti_auto === true) {
      const youtubeForActionWarningLink = 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/1589968917/Youtube+for+Action+alpha#IO-%26-setup-recommendations'
      return (
        `
            <span>
              Otto cold start <strong>does not work for this KPI at the moment</strong>.
              Input a manual A value based on the <strong>historical CPA or the CPA target</strong>.<br/>
              <a href="${youtubeForActionWarningLink}" target="_blank">More information here</a>
            </span>
          `
      )
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */

  warningYoutubeSurcoucheRatioOttoDeactivated (editedItem, instructionContainer, dsp) {
    if (dsp !== $YOUTUBE) {
      return false
    }
    if (!editedItem.opti_auto && editedItem.use_opti_ratio_surcouche) {
      return 'Surcouche ratio will not be applied. In Youtube, Otto needs to be activated to apply Surcouche ratio.'
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */

  warningThirdPartyConstraintsOptiRatio (editedItem, instructionContainer, dsp) {
    const insWithoutOptiRatio = instructionContainer.filter(instru => instru.opti_ratio)

    if (editedItem?.p3_obj_watcher?.kpi_type && insWithoutOptiRatio.length) {
      return 'You\'ve added surcouche ratio with a 3rd party KPI constraint, be aware that these ratio will not be taken into account.'
    }

    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  warningOttoMinVizTheTradeDesk (editedItem, instructionContainer, dsp) {
    if (dsp !== $THETRADEDESK) {
      return false
    }

    if (editedItem.otto_min_viz) {
      return 'Otto min viz is only compatible for clients using DoubleVerify as the Viewability provider at the moment.'
    }

    return false
  }
}

export {
  WarningsChecker
}
