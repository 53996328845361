// THX to : https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage/7128796#7128796
// Fiddle :
// https://jsfiddle.net/JeancarloFontalvo/1sco9Lpe/3/
// Mister @Jacob's Anwser
const percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } }, //  255, 0, 0
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } }, //  255, 255, 0
  { pct: 1.0, color: { r: 0x00, g: 0xA8, b: 0 } } ] //  0, 255 (0xff), 0 => updated for 168 (0xA8)

export function getColorForPercentage (pct: number): string {
  let i
  for (i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break
    }
  }
  let lower = percentColors[i - 1]
  let upper = percentColors[i]
  let range = upper.pct - lower.pct
  let rangePct = (pct - lower.pct) / range
  let pctLower = 1 - rangePct
  let pctUpper = rangePct
  let color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  }
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'
  // or output as hex if preferred
}
