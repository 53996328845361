import BriefModel from '../../src/models/Briefs/BriefModel'
import { DspInstruction, Instruction, InstructionDsp, MaxFrequency } from '../../types/instruction_type'
import { isAppx, isDbm, isMediamath, isTheTradeDesk, isYoutube } from './isIns'
import { ConversionFunnelInstruction } from '../../types/brief_type'
import { areConversionFunnelEqual } from '../../src/models/Helpers/conversionFunnelHelper'
import { KpiValue, Period } from '../../types/brief_enum'

export const warningPrefillMessage = 'Different from Briefing Instructions'

export function hasWarningRemoveDeprecatedUrl (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isDbm(instruction, dsp) || isYoutube(instruction, dsp)) {
    return instruction.automatically_remove_deprecated_url !== brief.constraints.automaticallyRemoveDeprecatedUrl
  }
  return false
}

export function hasWarningBudgetRepartitionAppx (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isAppx(instruction, dsp)) {
    if (brief.constraints.budgetRepartition) {
      return !(instruction.remove_daily_budget_global && !instruction.remove_daily_budget_campaigns_only)
    } else {
      return !(!instruction.remove_daily_budget_global && !instruction.remove_daily_budget_campaigns_only)
    }
  }
  return false
}

export function hasWarningBudgetRepartitionMediamath (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isMediamath(instruction, dsp)) {
    const budgetRepartition = !!brief.constraints.budgetRepartition
    return !!instruction.remove_budget_strat_imp !== budgetRepartition ||
      !!instruction.remove_budget_strat_money !== budgetRepartition
  }
  return false
}

export function hasWarningBudgetRepartitionDbm (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isDbm(instruction, dsp)) {
    return instruction.overwrite_daily_budget_li !== brief.constraints.budgetRepartition
  }
  return false
}

export function hasWarningBudgetRepartitionYoutube (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isYoutube(instruction, dsp)) {
    return !!instruction.overwrite_li_budget !== !!brief.constraints.budgetRepartition
  }
  return false
}

export function hasWarningBudgetRepartitionTheTradeDesk (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isTheTradeDesk(instruction, dsp)) {
    const budgetRepartition = !!brief.constraints.budgetRepartition
    return !!instruction.overwrite_daily_budget_impressions_adgroup !== budgetRepartition ||
      !!instruction.overwrite_daily_budget_money_adgroup !== budgetRepartition
  }
  return false
}

export function hasWarningCreativeActivity (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isAppx(instruction, dsp) || isDbm(instruction, dsp)) {
    return instruction.overwrite_creative_selection !== brief.constraints.creativeActivity
  }
  return false
}

export function hasWarningFoldPosition (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isAppx(instruction, dsp) || isDbm(instruction, dsp) || isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
    return instruction.remove_fold_position !== !!brief.constraints.foldPosition
  }
  return false
}

export function hasWarningFrequencyCap (brief: BriefModel, maxFrequency: MaxFrequency, overwriteFrequency: boolean) {
  if (!!brief.constraints.allowFrequencyCap !== !!overwriteFrequency) {
    return false
  }

  let amountCheck = false
  if (!(brief?.constraints?.frequencyCapPeriod === Period.Lifetime && maxFrequency.period === Period.Lifetime)) {
    amountCheck = brief?.constraints?.frequencyCapAmount && (brief?.constraints?.frequencyCapAmount !== maxFrequency.amount)
  }
  const exposureCheck = brief?.constraints?.frequencyCapExposure && (brief?.constraints?.frequencyCapExposure !== maxFrequency.exposures)
  const periodCheck = brief?.constraints?.frequencyCapPeriod && (brief?.constraints?.frequencyCapPeriod !== maxFrequency.period)
  return amountCheck || exposureCheck || periodCheck
}

export function hasWarningFrequencyTarget (brief: BriefModel, maxFrequency: MaxFrequency, overwriteFrequency: boolean) {
  if (!!brief.constraints.allowFrequencyTarget !== !!overwriteFrequency) {
    return false
  }

  let amountCheck = false
  if (!(brief?.constraints?.frequencyTargetPeriod === Period.Lifetime && maxFrequency.period === Period.Lifetime)) {
    amountCheck = brief?.constraints?.frequencyTargetAmount && (brief?.constraints?.frequencyTargetAmount !== maxFrequency.amount)
  }
  const exposureCheck = brief?.constraints?.frequencyTargetExposure && (brief?.constraints?.frequencyTargetExposure !== maxFrequency.exposures)
  const periodCheck = brief?.constraints?.frequencyTargetPeriod && (brief?.constraints?.frequencyTargetPeriod !== maxFrequency.period)
  return amountCheck || exposureCheck || periodCheck
}

export function hasWarningBidAdjustmentsOnDevice (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isYoutube(instruction, dsp)) {
    return instruction.overwrite_bid_adjustments_on_device !== brief.constraints.overwriteBidAdjustmentsOnDevice
  }
  return false
}

export function hasWarningTimeParting (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isAppx(instruction, dsp)) {
    return !(instruction.remove_time_parting === brief.constraints.timeParting)
  } else if (isDbm(instruction, dsp)) {
    return !(instruction.keep_timeparting === !brief.constraints.timeParting)
  } else if (isTheTradeDesk(instruction, dsp) || isMediamath(instruction, dsp)) {
    return !(instruction.remove_timeparting === brief.constraints.timeParting)
  }
  return false
}

export function hasWarningViewabilitySettings (brief: BriefModel, instruction: Instruction, dsp: InstructionDsp) {
  if (isAppx(instruction, dsp)) {
    return !(instruction.remove_min_viz === brief.constraints.viewabilitySettings)
  } else if (isDbm(instruction, dsp)) {
    return !(instruction.keep_min_viz === !brief.constraints.viewabilitySettings)
  } else if (isTheTradeDesk(instruction, dsp)) {
    return !(instruction.overwrite_viewability === brief.constraints.viewabilitySettings)
  }
  return false
}

export function hasWarningConversionFunnel (funnel: ConversionFunnelInstruction, instruction: Instruction, kpiValue: KpiValue) {
  return [KpiValue.CPA, KpiValue.CPA_PC].includes(kpiValue) && !areConversionFunnelEqual(funnel, instruction.true_conversion_funnel_pixel_ids)
}

export function hasWarningKpi (brief: BriefModel, instruction: Instruction) {
  return brief.KPI !== instruction.true_KPI_to_optimize
}

export function hasWarningKey (key: KeysOf<DspInstruction>, brief: BriefModel, instruction: Instruction, dsp: InstructionDsp, funnel: ConversionFunnelInstruction) {
  switch (key) {
    case 'automatically_remove_deprecated_url':
      return hasWarningRemoveDeprecatedUrl(brief, instruction, dsp)
    case 'overwrite_daily_budget_li':
      return hasWarningBudgetRepartitionDbm(brief, instruction, dsp)
    case 'overwrite_li_budget':
      return hasWarningBudgetRepartitionYoutube(brief, instruction, dsp)
    case 'remove_budget_strat_imp':
    case 'remove_budget_strat_money':
      return hasWarningBudgetRepartitionMediamath(brief, instruction, dsp)
    case 'overwrite_daily_budget_impressions_adgroup':
      return hasWarningBudgetRepartitionTheTradeDesk(brief, instruction, dsp)
    case 'overwrite_creative_selection':
      return hasWarningCreativeActivity(brief, instruction, dsp)
    case 'remove_fold_position':
      return hasWarningFoldPosition(brief, instruction, dsp)
    case 'overwrite_bid_adjustments_on_device':
      return hasWarningBidAdjustmentsOnDevice(brief, instruction, dsp)
    case 'remove_time_parting':
    case 'keep_timeparting':
    case 'remove_timeparting':
      return hasWarningTimeParting(brief, instruction, dsp)
    case 'remove_min_viz':
    case 'keep_min_viz':
    case 'overwrite_viewability':
      return hasWarningViewabilitySettings(brief, instruction, dsp)
    case 'true_conversion_funnel_pixel_ids':
      return hasWarningConversionFunnel(funnel, instruction, brief.KPI)
    case 'true_KPI_to_optimize':
      return hasWarningKpi(brief, instruction)
    default:
      return false
  }
}
