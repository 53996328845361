
import { checkGroupKeyMixin } from '../../../../mixins/checkGroupKeyMixin'
import LabelValueToolTip from '../../../TableComponents/Outcomes/OutcomesTable/FragmentsTable/LabelValueToolTip.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import { getClassStratLvl } from '../../../../../utils/stratLvlUtils'
import { StatusInstruction } from '../../../../../types/instruction_type'
import CheckPreMepCard from '../../../CheckPreMepComponents/CheckPreMepCard.vue'
import BaseCardDialog from '../../../Common/BaseCardDialog.vue'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import { checkPreMep } from '../../../../../config/featureReferenceConstant'
import { isActivatedCheckPreMep } from '../../../../../config/featureRefHelper'

const BLOCKING = 'BLOCKING'
const TO_IMPROVE = 'TO IMPROVE'
const GOOD = 'GOOD'

@Component({
  components: {
    LabelValueToolTip,
    CheckPreMepCard,
    BaseCardDialog
  }
})
export default class LocalizationHeaderForm extends mixins(checkGroupKeyMixin) {
  @Prop({ required: false, default: false }) ioFormTopbar: Boolean
  @PropSync('stratLvlProp') stratLvl: StratLvl
  @Prop({ required: false, default: false }) instructionStatus: StatusInstruction

  stratLvlItem: StratLvl[] = [
    'standard',
    'under_monitoring'
  ]

  dialogCheckPreMep = false

  checkPreMepBtnTooltip = 'Click for more details'

  getClassStratLvl (stratLvl: StratLvl) {
    return getClassStratLvl(stratLvl)
  }

  isActivatedCheckPreMep () {
    const newFeaturesRef: NewFeatureReferenceModel[] = this.$store.getters.getCurrentNewFeatureReferences
    return isActivatedCheckPreMep(newFeaturesRef, this.dsp)
  }

  getNbCols () {
    return this.errorAreDisplay ? 10 : 12
  }
  get ioValue () {
    if (!this.isGroupKeyValid || !this.overview?.external_id) {
      return this.insertionOrderId
    }
    return `${this.overview.external_id} (${this.overview.name})`
  }
  get adValue () {
    if (!this.isGroupKeyValid || !this.overview?.advertiser?.external_id) {
      return this.advertiserId
    }
    return `${this.overview.advertiser.external_id} (${this.overview.advertiser.name})`
  }
  get memberValue () {
    if (!this.isGroupKeyValid || !this.overview?.member?.external_id) {
      return this.clientId
    }
    return `${this.overview.member.external_id} (${this.overview.member.name})`
  }
  get displayErrorGroupKey () {
    return !this.isGroupKeyValid
  }
  get displayErrorDspConfig () {
    return !this.isClientIdInDspConfig && !this.isLoadingDspConfig
  }
  get errorAreDisplay () {
    return this.displayErrorGroupKey || this.displayErrorDspConfig
  }
  get checkPreMepResult () {
    return this.$store.getters.getCheckPreMepResult
  }
  get checkPreMepBtnLabel () {
    const checkPreMepState = this.checkPreMepResult?.check_setup_state
    if (['blocking_editable', 'blocking_non_editable'].includes(checkPreMepState)) {
      return BLOCKING
    } else if (checkPreMepState === 'non_blocking_editable') {
      return TO_IMPROVE
    } else if (checkPreMepState === 'good') {
      return GOOD
    }
    // default is blocking
    return BLOCKING
  }

  get checkPreMepIsGood () {
    return this.checkPreMepResult?.check_setup_state === 'good'
  }
  get checkPreMepBtnIcon () {
    if (this.checkPreMepBtnLabel === BLOCKING) {
      return 'close'
    } else if (this.checkPreMepBtnLabel === TO_IMPROVE) {
      return 'remove'
    }
    return 'done'
  }

  get classBtnCheckPreMep () {
    if (this.checkPreMepBtnLabel === BLOCKING) {
      return 'blocking'
    } else if (this.checkPreMepBtnLabel === TO_IMPROVE) {
      return 'to-improve'
    }
    return 'good'
  }

  get titleCheckPreMep () {
    return `
      Check-PreMep result :
      | Io : ${this.ioValue}
      | Dsp : ${this.$route.params.dsp}
    `
  }

  @Watch('insertionOrderId', { immediate: true, deep: true })
  onInsertionOrderIdChange (newValue: any) {
    this.$nextTick(() => {
      this.checkClientId()
    })
  }
}
