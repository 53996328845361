
import Vue from 'vue'
import Component from 'vue-class-component'
import * as rules from '../../../../rules/rules'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class BulkEditLine extends Vue {
  @Prop({ required: true, default: null }) model: any
  @Prop({ required: true, default: null }) label: string
  @Prop({ required: true, default: true }) isBooleanField: boolean

  // Model copy value to prevent modifying the original one directly
  value: any = null

  valueFieldType: string = null

  created () {
    this.value = this.model
  }

  changeBooleanField (newValue: boolean) {
    this.value.value = this.value.value !== newValue ? newValue : null
    this.emitChanges()
  }

  changeValueTypeField (newValue: string) {
    this.value.type = this.value.type !== newValue ? newValue : null
    if (this.value.type == null) {
      this.value.value = null
    }
  }

  // v-text-fields conditionnal prefixes / suffixes
  getPrefix () {
    if (this.value.type === 'perc') { return 'Add' } else { return '' }
  }

  getSuffix () {
    if (this.value.type === 'perc') { return '%' } else { return '' }
  }

  get isPercType () { return this.value.type === 'perc' }

  errorMessages (errorType: string, relativeType: string, value: any) {
    if (errorType === 'perc' && relativeType === 'perc' && value != null && value.length > 0) {
      const check = rules.percentage(value)
      if (check !== true) { return check }
    }
    return ''
  }

  emitChanges () {
    this.$emit('update:model', this.value)
  }

  resetComponent () {
    this.value = { type: null, value: null }
  }
}
