
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ButtonSwitch extends Vue {
  @Prop({ required: false, default: null }) model: any
  @Prop({ required: false, default: 'Main Label' }) mainLabel: string
  @Prop({ required: false, default: 'Label button one' }) labelButtonOne: string
  @Prop({ required: false, default: 'Label button two' }) labelButtonTwo: string
  @Prop({ required: false, default: null }) valueOne: any
  @Prop({ required: false, default: null }) valueTwo: any
  @Prop({ required: false, default: false }) required: boolean
  @Prop({ required: false, default: false }) disabledValueOne: boolean
  @Prop({ required: false, default: false }) disabledValueTwo: boolean
  @Prop({ required: false, default: null }) tooltipOne: string
  @Prop({ required: false, default: null }) tooltipTwo: string
  @Prop({ required: false, default: (): string[] => [] }) errorMessages: string[]
  @Prop({ required: false, default: '' }) hint: string

  value: any = null

  mounted () {
    this.value = this.model
  }

  selectNewValue (newValue: any) {
    if (this.value == null || this.value !== newValue) {
      this.value = newValue
      this.$emit('update:model', this.value)
    }
  }

  errorMessageButtonSwitch () {
    let error = this.isValueMissing ? ['error'] : []

    if (this.errorMessages) {
      error = [...error, ...this.errorMessages]
    }
    return error
  }

  get isValueMissing () {
    return this.required && (this.value == null || this.value === '')
  }
  get errorMessage () {
    return `${this.mainLabel} is mandatory.`
  }
}
