
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { DspInstruction, InstructionDsp, IoBrief } from '../../../../../../types/instruction_type'
import { snakeCaseConverterMixin } from '../../../../../mixins/snakeCaseConverterMixin'
import { CustomProdManagementModuleModel } from '../../../../../models/Keystone_v2/CustomProdMgmtModuleModel'
import SiegeModel from '../../../../../models/Keystone_v2/SiegeModel'
import { FormTitle } from '../../FormFragments'
import CheckBoxWithInfo from '../../../../Common/CheckBoxWithInfo.vue'
import { $APPNEXUS, $MEDIAMATH, $BEESWAX, $DBM, $THETRADEDESK } from '../../../../../../config/dspConfig'
import SiegeSettingModel from '../../../../../models/Keystone_v2/SiegeSettingModel'
import InstructionGetterSetter from '../../../../../models/Overlay/InstructionGetterSetter'

@Component({
  components: {
    FormTitle,
    CheckBoxWithInfo
  }
})
export default class BriefCustomProdManagement extends mixins(snakeCaseConverterMixin) {
  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: false, default: null }) editedItem: IoBrief<DspInstruction>
  @Prop({ required: false, default: null }) inheritedSiege: SiegeModel
  @Prop({ required: false, default: (): SiegeSettingModel => new SiegeSettingModel() }) inheritedKeystoneSiegeSettings: SiegeSettingModel
  @Prop({ required: false, default: '' }) dsp: InstructionDsp
  @Prop({ required: false, default: new CustomProdManagementModuleModel() }) inheritedCustomProdMgmtModule: CustomProdManagementModuleModel

  showCustomProdMgmtModule: boolean = true

  isInherited: boolean = true
  customProdMgmtModule: CustomProdManagementModuleModel = new CustomProdManagementModuleModel()

  // variable to know if it's the init or user changes
  isInit: boolean = true

  mounted () {
    if (this.editedItem != null) {
      // Default is true. If no siege is found to be linked, put it to false to prevent bad behaviours
      if (this.inheritedSiege == null || this.insGetterSetter.customProdManagementIsInheritedFromSeat === false) {
        this.insGetterSetter.customProdManagementIsInheritedFromSeat = false
        this.setCustomProdMgmtModule(false)
      } else if (this.inheritedSiege && this.insGetterSetter.customProdManagementIsInheritedFromSeat) {
        this.setCustomProdMgmtModule(true)
      }
    }
  }

  setCustomProdMgmtModule (keepInheritedValues = false) {
    if (keepInheritedValues === false) {
      this.customProdMgmtModule = this.insGetterSetter.customProdManagementModule != null
        ? new CustomProdManagementModuleModel(this.insGetterSetter.customProdManagementModule)
        : new CustomProdManagementModuleModel()
    } else {
      this.customProdMgmtModule = this.inheritedCustomProdMgmtModule
    }
  }

  // GETTERS
  get inheritedFromSeat () {
    return this.insGetterSetter.customProdManagementIsInheritedFromSeat
  }
  get labelRespectMinimumLiBudget () {
    let lineLabel = ''

    if ([$APPNEXUS, $BEESWAX, $DBM].indexOf(this.dsp) !== -1) {
      lineLabel = 'LI'
    } else if ([$MEDIAMATH].indexOf(this.dsp) !== -1) {
      lineLabel = 'Strategy'
    } else if ([$THETRADEDESK].indexOf(this.dsp) !== -1) {
      lineLabel = 'Adgroup'
    } else {
      lineLabel = 'LI'
    }
    return `Respect Minimum ${lineLabel} Budget`
  }
  getConvertedCustomProdMgmtModule () {
    return this.customProdMgmtModule.getObjectForApi()
  }

  @Watch('insGetterSetter.customProdManagementIsInheritedFromSeat')
  onCustomProdManagementIsInheritedFromSeatChange (value: boolean) {
    if (this.inheritedSiege != null) {
      if (value === true) {
        this.customProdMgmtModule = this.inheritedCustomProdMgmtModule != null
          ? this.inheritedCustomProdMgmtModule
          : new CustomProdManagementModuleModel()
      } else {
        this.setCustomProdMgmtModule(true)
      }
    }
  }
}
