import { camelToSnake } from '../../../utils/commonUtils'

export class CustomProdManagementModuleModel {
  constructor (data = {}) {
    this.underdeliveryCustom = data != null && data.underdelivery_custom != null
      ? data.underdelivery_custom
      : false

    this.inverseOttoWaterfall = data != null && data.inverse_otto_waterfall != null
      ? data.inverse_otto_waterfall
      : false

    this.overdeliveryCustom = data != null && data.overdelivery_custom != null
      ? data.overdelivery_custom
      : false

    this.respectMinimumLiBudget = data != null && data.respect_minimum_li_budget != null
      ? data.respect_minimum_li_budget
      : false
  }

  setToDefaultValues () {
    this.underdeliveryCustom = false
    this.inverseOttoWaterfall = false
    this.overdeliveryCustom = false
    this.respectMinimumLiBudget = false
  }

  getObjectForApi () {
    let toSnakeCase = {}

    for (let i in this) {
      if (!this.hasOwnProperty(i)) {
        continue
      }
      // convert empty values to null
      if (this[i] === undefined || String(this[i]).trim() === '') {
        toSnakeCase[camelToSnake(i)] = null
      } else {
        toSnakeCase[camelToSnake(i)] = this[i]
      }
    }

    return toSnakeCase
  }
}
