
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import BriefModel from '@/models/Briefs/BriefModel'
import { recomposeFrequencyCap, recomposeFrequencyTarget } from '../../../../../types/brief_type_helper'
import _ from 'lodash'
import { htmlEntities } from '../../../../../utils/commonUtils'

type KeyBriefAck = {
  [key: string]: {
    value?: (brief: BriefModel) => string,
    isError?: (brief: BriefModel) => boolean,
    treatWarning?: (warning: string) => string
  }
}
// https://trello.com/c/T5eFncXh/1060-surcouche-acknowledgements-lors-du-remplissage-automatique-de-longlet-brief
@Component({})
export default class AcknowledgementsForm extends Vue {
  @Prop({ required: true })
  acknowledgements: AcknowledgmentInfo[]

  @Prop({ required: true })
  brief: BriefModel

  loadingAck = false

  indexAck = 0

  keyBriefAcknowledgment: KeyBriefAck = {
    'General Comment': {},
    'Budget Micro': {
      value: (brief: BriefModel) => {
        return htmlEntities(brief.budgetToSaturateInPriority)
      }
    },
    'Business Model': {
      value: (brief: BriefModel) => {
        return htmlEntities(brief.revenueType)
      }
    },
    'Margin Constraints': {
      value: (brief: BriefModel) => {
        return brief && brief.minMargin
          ? brief.minMargin.toString()
          : null
      }
    },
    'Main Kpi': {
      value: (brief: BriefModel) => {
        let v: string[] = []
        if (brief.KPI) {
          v.push(`KPI : <strong>${htmlEntities(brief.KPI)}</strong>`)
        }

        if (brief.boostTarget) {
          v.push(`boost_target : <strong>${htmlEntities(brief.boostTarget)}</strong>`)
        }

        if (brief.conversionMeasurementTool) {
          v.push(`conversion_measurement_tool : <strong>${htmlEntities(brief.conversionMeasurementTool)}</strong>`)
        }
        return v.join(' / ')
      },
      isError (brief: BriefModel) {
        return brief.conversionMeasurementTool && brief.conversionMeasurementTool === 'other'
      },
      treatWarning (warning: string) {
        return warning.replace('An Account Manager may contact you about this topic.', '')
      }
    },
    'Main Constraints': {
      value: (brief: BriefModel) => {
        let v: string[] = []
        if (brief.minCpm) {
          v.push(`min CPM : <strong>${brief.minCpm}</strong>`)
        }

        if (brief.maxCpm) {
          v.push(`max CPM : <strong>${brief.maxCpm}</strong>`)
        }

        if (brief.maxCPC) {
          v.push(`max CPC : <strong>${brief.maxCPC}</strong>`)
        }

        if (brief.maxCPCV) {
          v.push(`max CPCV : <strong>${brief.maxCPCV}</strong>`)
        }

        const tempMaxCPCmaxCPCV = (type: 'CPC'|'CPCV') => {
          return `A max ${type} has been communicated by the trader. Please convert it in your brief setup`
        }

        if (brief.maxCPC) {
          v.push(tempMaxCPCmaxCPCV('CPC'))
        }

        if (brief.maxCPCV) {
          v.push(tempMaxCPCmaxCPCV('CPCV'))
        }
        return v.join(' / ')
      },
      isError: (brief: BriefModel) => {
        return !!(brief.maxCPC || brief.maxCPCV)
      }
    },
    'Funnel': {},
    'View Rate': {
      value: (brief: BriefModel) => {
        if (!brief.constraints.allowViewRate) {
          return 'No Min Average viewability Rate Constraint'
        }
        return brief?.constraints?.viewRate
          ? brief.constraints.viewRate.toString()
          : null
      }
    },
    'Frequency Cap': {
      value: (brief: BriefModel) => {
        if (brief.constraints.overwriteFrequencyMode === 'allowed_with_constraints') {
          return recomposeFrequencyCap(brief)
        } else if (brief.constraints.overwriteFrequencyMode === 'not_allowed') {
          return 'AI is not allowed to overwrite frequency'
        } else if (brief.constraints.overwriteFrequencyMode === 'allowed_but_no_constraints') {
          return 'No Frequency Cap Constraint'
        } else {
          // old mode
          if (brief.constraints.allowFrequencyCap) {
            return recomposeFrequencyCap(brief)
          }
        }
        return 'No Frequency Cap Constraint'
      }
    },
    'Frequency Target': {
      value: (brief: BriefModel) => {
        if (!brief.constraints.allowFrequencyTarget) {
          return 'No Frequency Target Constraint'
        } else {
          return recomposeFrequencyTarget(brief)
        }
      }
    },
    'Viewability Settings': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.viewabilitySettings)
          ? brief.constraints.viewabilitySettings.toString()
          : null
      }
    },
    'Budget Repartition': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.budgetRepartition)
          ? brief.constraints.budgetRepartition.toString()
          : null
      }
    },
    'Time Parting': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.timeParting)
          ? brief.constraints.timeParting.toString()
          : null
      }
    },
    'Fold Position': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.foldPosition)
          ? brief.constraints.foldPosition.toString()
          : null
      }
    },
    'Creative Activity': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.creativeActivity)
          ? brief.constraints.creativeActivity.toString()
          : null
      }
    },
    'Allow Unknown Demographic': {
      value: (brief: BriefModel) => {
        return brief.constraints && !_.isNil(brief.constraints.allowUnknownDemographic)
          ? brief.constraints.allowUnknownDemographic
          : null
      }
    }
  }

  async isOk () {
    this.loadingAck = true
    this.currentAck.is_ok = true
    this.loadingAck = false
  }

  getValue (fieldName: string) {
    return fieldName in this.keyBriefAcknowledgment && typeof this.keyBriefAcknowledgment[fieldName].value === 'function'
      ? this.keyBriefAcknowledgment[fieldName].value(this.brief)
      : null
  }

  getType (fieldName: string) {
    return fieldName in this.keyBriefAcknowledgment && typeof this.keyBriefAcknowledgment[fieldName].isError === 'function' &&
    this.keyBriefAcknowledgment[fieldName].isError(this.brief)
      ? 'error'
      : 'warning'
  }

  getTreatWarning (fieldName: string, warning: string) {
    return fieldName in this.keyBriefAcknowledgment &&
      typeof this.keyBriefAcknowledgment[fieldName].treatWarning === 'function' && typeof warning === 'string'
      ? this.keyBriefAcknowledgment[fieldName].treatWarning(warning)
      : warning
  }

  removeHtmlEntities (str: string) {
    return this.$commonUtils.htmlEntities(str)
  }

  get currentAck (): AcknowledgmentInfo {
    return this.ackNotOk[this.indexAck] ? this.ackNotOk[this.indexAck] : null
  }

  get ackNotOk () {
    const sortByOrderAcknowledgment = (a: AcknowledgmentInfo, b: AcknowledgmentInfo) => {
      const indA = this.orderAcknowledgment.indexOf(a.field_name)
      const indB = this.orderAcknowledgment.indexOf(b.field_name)
      // the 2 are not in the array, same position
      if (indA === -1 && indB === -1) {
        return 0
        // 'b' is in array but 'a' not, 'a' must be after 'b'
      } else if (indA === -1 && indB !== -1) {
        return 1
        // 'a' is in array but 'b' not, 'a' must be before 'b'
      } else if (indB === -1 && indA !== -1) {
        return -1
      }
      // if the index of 'a' is lower or equal, 'a' must be before, otherwise 'b' must be before
      return indA <= indB ? -1 : 1
    }

    return this.acknowledgements.filter(item => !item.is_ok).sort(sortByOrderAcknowledgment)
  }

  get orderAcknowledgment () {
    return Object.keys(this.keyBriefAcknowledgment)
  }
}
