import { render, staticRenderFns } from "./CheckboxButton.vue?vue&type=template&id=3ffc0e26&scoped=true"
import script from "./CheckboxButton.vue?vue&type=script&lang=ts"
export * from "./CheckboxButton.vue?vue&type=script&lang=ts"
import style0 from "./CheckboxButton.vue?vue&type=style&index=0&id=3ffc0e26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffc0e26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VCol,VRow,VTooltip})
