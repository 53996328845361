import { InstructionDsp } from 'types/instruction_type'
import Component, { mixins } from 'vue-class-component'
import { generalMixin } from './generalMixin'
import { $YOUTUBE, $KAYZEN, $DBM } from '../../config/dspConfig'
import { Prop } from 'vue-property-decorator'

/**
 * mixin who contain function for check dsp dspConfig and io in cuicui by calling the api.
 * Warning: already contain generalMixin. If you add this mixin to a component, remove generalMixin from the component
 */
@Component({})
export class checkGroupKeyMixin extends mixins(generalMixin) {
  @Prop({ required: false }) insertionOrderId: String | Number
  @Prop({ required: false }) advertiserId: String | Number
  @Prop({ required: false }) clientId: String | Number

  dspNotToCheckGroupKey: Array<InstructionDsp> = [$KAYZEN]
  isLoadingDspConfig: Boolean = false
  isConfigStatusComplete: Boolean = false
  isClientIdInDspConfig: Boolean = false

  async checkClientId () {
    if (this.clientId === undefined || this.clientId === '') {
      return
    }
    this.isClientIdInDspConfig = false
    this.isConfigStatusComplete = false
    this.isLoadingDspConfig = true

    let dsp = this.dsp

    // special case youtube, where we check in dbm client
    if (this.dsp === $YOUTUBE) {
      dsp = $DBM
    }

    const resultConfig = await this.$apiCaller.getDspConfigs(dsp, { id: this.clientId })

    if (!this.$apiCaller.isResponseError(resultConfig, true)) {
      this.isClientIdInDspConfig = resultConfig.data.length !== 0
      if (this.isClientIdInDspConfig) {
        this.isConfigStatusComplete = resultConfig.data[0].is_active
      }
    } else {
      console.warn('Error when calling dsp config.')
    }
    this.isLoadingDspConfig = false
  }

  // GETTERS
  get overview () {
    return this.$store.getters.getCurrentOverview
  }
  get currentDspDontNeedToCheckGroupKey () {
    return this.dspNotToCheckGroupKey.indexOf(this.dsp) !== -1
  }
  get errorMessageGroupKey () {
    return `This ${this.getDspClientLabel}/${this.getAdLabelPerDsp}/${this.getInsertionOrderLabelPerIo} combo is not found in our DB, please make sure it exists`
  }
  get errorMessageClientNotInDspConfig () {
    if (!this.isClientIdInDspConfig) {
      return 'Client not set in dsp config'
    }
    return this.isConfigStatusComplete === true ? 'Status is complete' : 'Status is incomplete'
  }

  // SETTERS
  set overview (value: any) {
    this.$store.commit('setCurrentOverview', value)
  }

  get isGroupKeyValid () {
    if (this.currentDspDontNeedToCheckGroupKey) {
      return true
    }
    return !!this.$store.getters.getCurrentOverview
  }
}
