
import Component, { mixins } from 'vue-class-component'
import ConstraintsToRespectBlock from './ConstraintsToRespectBlock.vue'
import FormTitle from '../../FormFragments/FormTitle.vue'
import { Prop, Watch } from 'vue-property-decorator'
import InstructionGetterSetter from '../../../../../models/Overlay/InstructionGetterSetter'
import { KpiValue } from '../../../../../../types/brief_enum'
import {
  CheckValidatorResult,
  DspInstruction,
  IoBrief
} from '../../../../../../types/instruction_type'
import ButtonSwitch from '../../FormFragments/ButtonSwitch.vue'
import { snakeCaseConverterMixin } from '../../../../../mixins/snakeCaseConverterMixin'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import {
  checkPreMepValidatorToErrorMessage
} from '../../../../../../utils/CheckPreMep/CheckPremepValidatorUtils'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'

@Component({
  components: {
    ConstraintsToRespectBlock,
    FormTitle,
    ButtonSwitch
  }
})
export default class BriefConstraints extends mixins(snakeCaseConverterMixin, checkPremepValidatorMixins) {
  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: true, default: false }) untie: boolean
  @Prop({ required: true, default: function (): Array<KpiValue> { return [] } }) kpiToOptimizeOptions: Array<KpiValue>
  @Prop({ required: true, default: {} }) maxFrequencyValue: MaxFrequencyValueType
  @Prop({ required: true, default: {} }) targetFrequencyValue: MaxFrequencyValueType
  @Prop({ required: true, default: null }) editedItem: IoBrief<DspInstruction>
  @Prop({ required: true, default: false }) isInstruCreatedViaDataform: boolean
  @Prop({ required: true, default: false }) dsp: string
  @Prop({ required: true }) keystoneMemberSetting: MemberSettingModel

  mounted () {}

  setRevenueTypeFromBudgetType () {
    this.insGetterSetter.revenueType = this.insGetterSetter.typeOfBudgetSaturation === 'imp' ? 'rev_cpm' : 'cost_plus'
  }

  async getBrief () {
    const result = await this.$apiCaller.getBriefOfExternalId(this.editedItem.io, this.dsp)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error for getBrief', result)
    } else {
      if (result.data.length > 0) {
        return result.data[0]
      }
      return null
    }
  }

  // EMITTERS
  /**
   * called when ConstraintsForm emit updateConstraints
   * @param computed {NonMethodKeys<InsGetterSetter>} the computed attribute to update with value
   * @param value {Number} value
   * @param noConstraint {Boolean} Is noConstraint checked ?
   */
  updateConstraints (computed: any, value: Number, noConstraint: boolean) {
    computed = computed as keyof InstructionGetterSetter
    if (computed != null) { this.insGetterSetter[computed] = value }
  }
  launchTimeOutBadge () {
    this.$emit('launchTimeOutBadge')
  }
  updateMaxFrequency (newValue: any) {
    this.insGetterSetter.maxFrequency = newValue
  }
  updateTargetFrequency (newValue: any) {
    this.insGetterSetter.targetFrequency = newValue
  }
  updateOverwriteFrequency (newValue: any) {
    this.insGetterSetter.overwriteFrequency = newValue
  }
  updateOverwriteFrequencyTarget (newValue: any) {
    this.insGetterSetter.overwriteFrequencyTarget = newValue
  }
  // LISTENERS
  updateRevenueType (value: 'cost_plus' | 'rev_cpm') {
    this.insGetterSetter.useCustomAlgo = value === 'cost_plus'
  }

  isDisabledRevenueTypeRevCpm () {
    // revenueType rev_CPM is disabled when waterfallPriority is perf first, but should not be disabled
    // if the originalValue (value when the insGetterSetter before opening the form)
    // is already set to rev_CPM (just a warning will pop)
    return this.insGetterSetter.waterfallPriority === 'perf_first' &&
      this.insGetterSetter.revenueType !== 'rev_cpm'
  }

  tooltipRevCpm () {
    if (this.isDisabledRevenueTypeRevCpm()) {
      return 'Revenue type can not be set to rev_cpm when warterfall_priority is set to perf_first.'
    }
  }

  errorMessagesRevenueType () {
    let errors: string[] = []

    if (!this.isWarningCPMValidator()) {
      errors = [...errors, ...this.getErrorRelatedToFieldCPMValidator(['revenue_type'])]
    }

    return errors
  }

  hintMessageRevenueType () {
    if (this.isWarningCPMValidator()) {
      const errors = this.getWarningRelatedToFieldCPMValidator(['revenue_type'])
      if (errors.length > 0) {
        return errors.join(' ')
      }
    }
    return ''
  }

  @Watch('insGetterSetter.typeOfBudgetSaturation')
  onBudgetTypeChange () {
    this.setRevenueTypeFromBudgetType()
  }
}
