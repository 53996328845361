
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch, PropSync } from 'vue-property-decorator'
import { InstructionNotes } from '../../../../../types/instruction_type'
import SimplePrompt from '@/components/Common/SimplePrompt.vue'

@Component({
  components: { SimplePrompt }
})
export default class InstruNotesComponent extends Vue {
  @Prop()
  insertionOrderId: string

  @Prop()
  dsp: string

  @PropSync('countNote')
  count!: number

  loading = false
  processing = false
  error = false
  errorProcessing = false
  successAlert = false
  instruNotes: InstructionNotes[] = []
  newNote = ''
  showComment = false
  successDelete = false
  errorDelete = false
  loadingDelete = false
  dialogConfirm = false
  noteToCancel: number = null

  mounted () {
    this.getInstruNotes()
  }

  async getInstruNotes () {
    this.loading = true
    this.error = false
    const response = await this.$apiCaller.getInstructionNotes(this.insertionOrderId, this.dsp)
    if (this.$apiCaller.isResponseError(response)) {
      this.error = true
    } else {
      this.instruNotes = response.data
    }
    this.loading = false
  }

  cancel () {
    this.newNote = ''
    this.showComment = false
  }

  displayDate (date: string) {
    if (!date) {
      return null
    }
    let toDate = new Date(date)
    return toDate.toISOString().split('T')[0] + ' ' + toDate.toLocaleTimeString()
  }

  async postNewNotes () {
    this.processing = true
    const response = await this.$apiCaller.postInstructionNotes(this.insertionOrderId, this.dsp, this.newNote)
    if (this.$apiCaller.isResponseError(response)) {
      this.errorProcessing = true
    } else {
      this.successAlert = true
      this.newNote = ''
      this.showComment = false
      await this.getInstruNotes()
    }
    this.processing = false
  }

  cancelDelete () {
    this.dialogConfirm = false
    this.noteToCancel = null
  }

  disableDeleteNote (noteWriterMail: string) {
    let currentUser = this.$store.getters.getCurrentUser
    let noteWriter = noteWriterMail.split('@')[0]
    return noteWriter !== currentUser
  }

  processDeleteNote (noteId: number) {
    this.dialogConfirm = true
    this.noteToCancel = noteId
  }

  async deleteNote () {
    this.loadingDelete = true
    this.errorDelete = false
    this.successDelete = false
    let response = await this.$apiCaller.deleteInstruNote(this.noteToCancel)
    if (this.$apiCaller.isResponseError(response)) {
      this.errorDelete = true
    } else {
      this.successDelete = true
      await this.getInstruNotes()
    }
    this.dialogConfirm = false
    this.loadingDelete = false
  }

  get notesByDate (): InstructionNotes[] {
    return this.instruNotes.sort(function (a: InstructionNotes, b: InstructionNotes) {
      return new Date(b.insertion_date).getTime() - new Date(a.insertion_date).getTime()
    })
  }

  @Watch('successAlert')
  onSuccessAlert () {
    if (this.successAlert) {
      setTimeout(() => { this.successAlert = false }, 3000)
    }
  }

  @Watch('instruNotes', { deep: true })
  onChangeInstruNotes (instruNotes?: InstructionNotes[]) {
    if (Array.isArray(instruNotes)) {
      this.count = instruNotes.length
    }
  }
}
