<template>
  <v-card>
    <v-card-title class="grey lighten-2 body-2 400">
      Baseline - what are we compared to ?
    </v-card-title>
    <v-card-text>
      <v-tooltip top :disabled="!disabledBaselineForm">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-radio-group mandatory
                           :value="type"
                           @change="emitSync('baseline_type', $event)"
            >
              <v-radio
                :disabled="disabledBaselineForm"
                value="ab_test" label="It's an AB test">
              </v-radio>
              <v-row class="pl-5" wrap v-show="type === 'ab_test'">
                <v-col cols="9" class="py-1">
                  <OverviewCombobox
                    :external-ids="baselineIo"
                    @update:externalIds="emitSyncComboboxBaselineIo"
                    :is-disabled="isDisabledBaselineIo || disabledBaselineForm"
                    :label="'Enter Control ' + ioLabel"
                    :error-messages="errorMessageBaselineIo()"
                  >
                  </OverviewCombobox>
                </v-col>
                <v-col cols="9" class="py-1">
                  <!-- unknown value checkbox -->
                  <v-checkbox
                    :disabled="type !== 'ab_test' || baseline.baseline_io && baseline.baseline_io.length > 0"
                    :input-value="baseline.baseline_io_unknown"
                    @change="updateIsUnknown($event)"
                    label="Unknown"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="comment-container-baseline">
                  <v-expansion-panels popout>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div class="label-comments">Comments?</div>
                      </v-expansion-panel-header>
                      <v-card width="100%">
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-textarea
                                class="comments-baseline"
                                :value="baseline.comments"
                                @change="emitSync('comments', $event)"
                              >
                              </v-textarea>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <DuoDatePicker
                :disabled="type !== 'ab_test' || disabledBaselineForm"
                :required="type === 'ab_test'"
                v-show="type === 'ab_test'"
                label-start="AB Test start date"
                label-end="AB Test end date"
                :start.sync="abTestStartDateComputed"
                :end.sync="abTestEndDateComputed"
                tab="tab1"
                class="pl-12"
                :ref="abTestDatePickerRef"
              >
              </DuoDatePicker>

              <!-- Ai performance -->
              <v-row
                class="pl-12"
                v-show="aiPerformanceShouldBeAsked && type === 'ab_test'"
              >
                <v-col>
                  <v-radio-group
                    :disabled="isDisabledAiPerformance()"
                    :value="baseline.ai_performance"
                    @change="emitSync('ai_performance', $event)"
                    :error-messages="errorMessageAiPerformance()"
                  >
                    <v-row wrap>
                      <v-col cols="12" class="label-ai-performance">
                        {{labelAiPerformanceInput}}
                      </v-col>
                      <v-col
                        v-for="(item, key) in itemsAiPerformanceCombobox"
                        :key="key"
                      >
                        <v-radio

                          :label="item.text"
                          :value="item.value"
                        >
                        </v-radio>
                      </v-col>
                    </v-row>

                  </v-radio-group>
                </v-col>
              </v-row>
              <v-alert :value="displayAlert" type="info">
                {{controlIoEndTooEarlyErrorMessage}}
              </v-alert>
              <v-tooltip
                top
                :disabled="!baselineTypeCantBeUpdated"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-radio
                      :disabled="baselineTypeCantBeUpdated || disabledBaselineForm"
                      value="before_after"
                      label="It's a before after comparison"
                    >
                    </v-radio>
                    <DuoDatePicker
                      class="pl-12"
                      v-show="type === 'before_after'"
                      :disabled="type !== 'before_after' || disabledBaselineForm"
                      :required="type === 'before_after'"
                      label-start="Start date (Before Period)"
                      label-end="End date (Before Period)"
                      :start.sync="beforeStartDateComputed"
                      :end.sync="beforeEndDateComputed"
                    >
                    </DuoDatePicker>
                    <v-radio
                      :disabled="baselineTypeCantBeUpdated"
                      value="other"
                      label="Other"
                    >
                    </v-radio>
                  </div>
                </template>
                <span>
               {{tooltipTextBaselineTypeCantBeUpdated}}
              </span>
              </v-tooltip>
            </v-radio-group>
          </div>
        </template>
        <span>
          {{messageDisabledBaselineForm}}
        </span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import { rulesMixin } from '../../../../mixins/rulesMixin'
import { dateToDatePickerDate } from '../../../../../utils/DatePickerHelper/datePickerHelper'
import DuoDatePicker from '@/components/TableComponents/Form/FormFragments/DuoDatePicker'
import _ from 'lodash'
import { NEW } from '../../../../../store'
import OverviewCombobox from '@/components/TableComponents/Form/FormFragments/OverviewCombobox'

export default {
  name: 'BaselineComponent',
  components: { DuoDatePicker, OverviewCombobox },
  props: {
    baseline: {
      type: Object,
      default: () => ({})
    },
    startBaseline: {
      type: Object,
      default: () => ({})
    },
    ioLabel: {
      type: String
    },
    currentIoId: {
      type: String
    }
  },
  mixins: [rulesMixin],
  data: function () {
    return {
      menuStartDate: false,
      menuEndDate: false,
      baselineOverview: [],
      itemsAiPerformanceCombobox: [
        {
          value: 'better',
          text: 'Better'
        },
        {
          value: 'mixed',
          text: 'Mixed'
        },
        {
          value: 'poorer',
          text: 'Poorer'
        },
        {
          value: 'cancelled',
          text: 'Cancelled'
        }
      ],
      loadingSearchOverview: false,
      controlIoEndTooEarly: false,
      tooEarlyEndDate: '',
      labelAiPerformanceInput: 'How has AI performed during AB Test ?',
      aiPerformanceShouldBeAsked: false,
      previousEndDate: '',
      errorMessageBaselineTypeCantBeUpdated: 'You can’t change the baseline while the AB test is happening. Please change end date if AB test is over.',
      errorMessageBaselineShouldBeSaved: 'Baseline type will be editable after saving the instructions.',
      messageDisabledBaselineForm: 'This AB Test is ended, please save and reopen to create a new baseline.',
      displayMessageBaselineShouldBeSaved: false,
      abTestDatePickerRef: 'abTestDatePicker'
    }
  },
  mounted () {
    this.checkAiPerformance()
  },
  methods: {
    emitSync (property, value) {
      if (typeof value === 'string') {
        value = value.trim()
      }
      this.syncBaseline(property, value)
    },
    emitSyncComboboxBaselineIo (value) {
      this.emitSync('baseline_io', value)
    },
    emitSyncDate (property, value) {
      if (typeof value === 'string') {
        value = new Date(value.trim()).toISOString()
      }
      this.syncBaseline(property, value)
    },
    syncBaseline (property, value) {
      const baseline = _.cloneDeep(this.baseline)
      baseline[property] = value
      this.$emit(`update:baseline`, baseline)
    },
    errorMessageBaselineIo () {
      const errors = []

      if (this.isDisabledBaselineIo) {
        return errors
      }

      const baselineIo = this.baseline.baseline_io

      if (Array.isArray(baselineIo) && baselineIo.includes(this.currentIoId)) {
        const message = 'Compared io can not be the same as current io.'
        errors.push(message)
      }

      if (!baselineIo || !baselineIo.length) {
        const message = 'Baseline Io must be completed.'
        errors.push(message)
      }

      return errors
    },
    async getBaselineIo () {
      if (!this.baselineIo || !this.baselineIo.length || this.baselineIo[0] === 'complex') {
        return
      }
      const params = {
        subDsp: this.$route.params.dsp,
        externalIdList: this.baselineIo
      }
      const response = await this.$apiCaller.getOverviewsWithSearch(params, false)

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling api')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else if (this.$apiCaller.isCancelError(response)) {
        console.warn('Request canceled')
      } else {
        if (response.data.length) {
          this.baselineOverview = response.data
          const billingEnds = this.baselineOverview.map((overview) => new Date(overview.billing_end))
          const maxBillingEnd = Math.max.apply(null, billingEnds)

          const startDate = new Date(this.abTestStartDate)
          if (startDate > maxBillingEnd) {
            this.controlIoEndTooEarly = true
            this.tooEarlyEndDate = dateToDatePickerDate(new Date(maxBillingEnd))
          } else {
            this.controlIoEndTooEarly = false
            if (!this.abTestEndDateComputed && maxBillingEnd) {
              this.abTestEndDateComputed = dateToDatePickerDate(new Date(maxBillingEnd))
            }
          }
        }
      }
    },
    /**
     * Prefill start date with today or billing start date if exists.
     * If the both exists, the max is taken.
     */
    prefillStartDate () {
      if (this.abTestStartDateComputed) {
        console.warn('Start date already filled')
        return
      }
      if (this.type === 'ab_test' && this.currentOverview) {
        const today = new Date()
        let startDate = null

        if (!this.currentOverview.billing_start) {
          startDate = dateToDatePickerDate(new Date(today))
        } else {
          startDate = Math.max.apply(null, [new Date(this.currentOverview.billing_start), today])
        }

        this.abTestStartDateComputed = dateToDatePickerDate(new Date(startDate))
      }
    },
    shouldAiPerformanceBeAsked () {
      return this.baseline?.ai_performance === 'to_be_defined' && this.type === 'ab_test' && this.isAbTestEnded
    },
    checkAiPerformance () {
      if (this.previousEndDate === this.abTestEndDate) {
        return
      }
      this.previousEndDate = this.abTestEndDate
      this.aiPerformanceShouldBeAsked = this.shouldAiPerformanceBeAsked()
    },
    errorMessageAiPerformance () {
      const errors = []

      if (this.aiPerformanceShouldBeAsked && this.baseline.ai_performance === 'to_be_defined') {
        const message = 'Ai performance must be completed.'
        errors.push(message)
      }

      return errors
    },
    updateIsUnknown (newValue) {
      if (newValue) {
        this.emitSync('baseline_io', null)
      }
      this.emitSync('baseline_io_unknown', newValue)
    },
    isDisabledAiPerformance () {
      const errors = [...this.errorMessageBaselineIo()]
      return errors.length > 0 || this.isAbTestDatePickerInError()
    },
    isAbTestDatePickerInError () {
      const datePicker = this.$refs[this.abTestDatePickerRef]
      if (datePicker) {
        const errors = datePicker.currentErrorMessages
        return errors.length > 0
      }
      return false
    }
  },
  computed: {
    beforeStartDateComputed: {
      get () {
        if (!this.beforeStartDate) {
          return this.beforeStartDate
        }
        return dateToDatePickerDate(new Date(this.beforeStartDate))
      },
      set (value) {
        this.emitSyncDate('before_start_date', value)
      }
    },
    beforeEndDateComputed: {
      get () {
        if (!this.beforeEndDate) {
          return this.beforeEndDate
        }
        return dateToDatePickerDate(new Date(this.beforeEndDate))
      },
      set (value) {
        this.emitSyncDate('before_end_date', value)
      }
    },
    abTestStartDateComputed: {
      get () {
        if (!this.abTestStartDate) {
          return this.abTestStartDate
        }
        return dateToDatePickerDate(new Date(this.abTestStartDate))
      },
      set (value) {
        this.emitSyncDate('ab_test_start_date', value)
      }
    },
    abTestEndDateComputed: {
      get () {
        if (!this.abTestEndDate) {
          return this.abTestEndDate
        }
        return dateToDatePickerDate(new Date(this.abTestEndDate))
      },
      set (value) {
        this.emitSyncDate('ab_test_end_date', value)
      }
    },
    currentOverview () {
      return this.$store.getters.getCurrentOverview
    },
    formStatus () {
      return this.$store.getters.getFormStatus
    },
    controlIoEndTooEarlyErrorMessage () {
      return `Control's flight ends on ${this.tooEarlyEndDate}, sooner than IO start.`
    },
    displayAlert () {
      return this.controlIoEndTooEarly && !this.abTestEndDateComputed
    },
    baselineIo () {
      return this.baseline?.baseline_io
    },
    type () {
      return this.baseline?.baseline_type
    },
    abTestStartDate () {
      return this.baseline?.ab_test_start_date
    },
    abTestEndDate () {
      return this.baseline?.ab_test_end_date
    },
    beforeStartDate () {
      return this.baseline?.before_start_date
    },
    beforeEndDate () {
      return this.baseline?.before_end_date
    },
    isAbTestEnded () {
      if (!this.abTestEndDate) {
        return false
      }
      const abTestEndDate = new Date(this.abTestEndDate)
      const today = new Date()
      return abTestEndDate < today
    },
    baselineTypeCantBeUpdated () {
      return this.formStatus !== NEW && this.type === 'ab_test' && this.startBaseline.ai_performance === 'to_be_defined'
    },
    displayMessageCantChangeBaselineTypeWhileABTestIsHappening () {
      return this.type === 'ab_test' && !this.isAbTestEnded
    },
    isDisabledBaselineIo () {
      return this.type !== 'ab_test' || this.baseline.baseline_io_unknown
    },
    tooltipTextBaselineTypeCantBeUpdated () {
      if (this.displayMessageBaselineShouldBeSaved) {
        return this.errorMessageBaselineShouldBeSaved
      } else if (this.displayMessageCantChangeBaselineTypeWhileABTestIsHappening) {
        return this.errorMessageBaselineTypeCantBeUpdated
      } else if (this.baselineTypeCantBeUpdated) {
        return 'Baseline type can\'t be updated while AI performance is not completed.'
      }
      return ''
    },
    disabledBaselineForm () {
      return this.baseline.baseline_type === 'ab_test' && this.baseline.ai_performance && this.baseline.ai_performance !== 'to_be_defined'
    }
  },
  watch: {
    type () {
      if (this.type === 'ab_test') {
        this.emitSync('before_start_date', null)
        this.emitSync('before_end_date', null)
        this.prefillStartDate()
      } else if (this.type === 'before_after') {
        this.emitSync('baseline_io', null)
        this.emitSync('ab_test_start_date', null)
        this.emitSync('ab_test_end_date', null)
      } else if (this.type === 'other') {
        this.emitSync('before_start_date', null)
        this.emitSync('before_end_date', null)
        this.emitSync('ab_test_start_date', null)
        this.emitSync('ab_test_end_date', null)
        this.emitSync('baseline_io', null)
      }
    },
    baselineIo: {
      deep: true,
      immediate: false,
      handler: function () {
        this.getBaselineIo()
      }
    },
    currentOverview: {
      deep: true,
      immediate: true,
      handler: function () {
        this.prefillStartDate()
      }
    },
    abTestEndDate: {
      deep: true,
      immediate: false,
      handler: function (value) {
        this.checkAiPerformance()
        // if aiPerformance should be asked after updating the abtestend date,
        // we will ask the user to save before being able to change the baselineType
        this.displayMessageBaselineShouldBeSaved = this.aiPerformanceShouldBeAsked
      }
    }
  }
}
</script>

<style>
.dp-text-field .v-input__control {
  width: 90%;
}

.label-ai-performance {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.label-comments {
  color: gray;
}

.comments-baseline .v-input__control {
  width: 100%;
}

.comment-container-baseline {
  padding-bottom: 2em;
}

.control-io-copy {
  width: 18px;
  height: 18px;
}
</style>
