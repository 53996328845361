import {
  Instruction,
  InstructionAppnexus, InstructionBeeswax,
  InstructionDbm,
  InstructionDsp,
  InstructionMediamath, InstructionTheTradeDesk, InstructionYoutube
} from '../../types/instruction_type'

import { Period } from '../../types/brief_enum'

export function isAppx (t: Instruction, dsp: InstructionDsp): t is InstructionAppnexus {
  return dsp === 'appnexus'
}

export function isDbm (t: Instruction, dsp: InstructionDsp): t is InstructionDbm {
  return dsp === 'dbm'
}

export function isMediamath (t: Instruction, dsp: InstructionDsp): t is InstructionMediamath {
  return dsp === 'mediamath'
}

export function isTheTradeDesk (t: Instruction, dsp: InstructionDsp): t is InstructionTheTradeDesk {
  return dsp === 'thetradedesk'
}

export function isYoutube (t: Instruction, dsp: InstructionDsp): t is InstructionYoutube {
  return dsp === 'youtube'
}

export function isBeeswax (t: Instruction, dsp: InstructionDsp): t is InstructionBeeswax {
  return dsp === 'beeswax'
}

export function isPeriod (a: any): a is Period {
  return Object.values(Period).includes(a)
}
