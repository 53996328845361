
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { BlockingInfo } from '../../../types/instruction_type'

@Component({
  components: {}
})
export default class ReasonMessageList extends Vue {
  @Prop()
  blockingInfo: BlockingInfo[]

  @Prop()
  title: string
}
