
import { defineComponent, PropType } from 'vue'
import { KpiValue } from '../../../../../../types/brief_enum'
import { PivotVariable } from '../../../../../../types/instruction_type'

export default defineComponent({
  name: 'PivotVariablesAutocomplete',
  props: {
    value: {
      type: [Array, null, undefined],
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    /**
     * check if the pivot variable list have maximum 3 element and do not have duplicate.
     */
    errorMessagesPivotVariable () {
      const errors = []

      if (this.isRequired &&
        (this.value == null || this.value.length < 1)) {
        errors.push('Pivot variables can\'t be empty.')
      }

      if (this.value && this.value.length > 3) {
        errors.push('You can\'t have more than 3 pivot variables.')
      }
      if (this.value && this.value.length > 1) {
        const uniquePivotVariable = [...new Set(this.value)]
        if (this.value.length !== uniquePivotVariable.length) {
          errors.push('You can\'t have duplicate pivot variables.')
        }
      }
      return errors
    }
  },
  computed: {
    itemsPivotVariable () {
      const pivotVariable: PivotVariable[] = this.$store.getters.getCurrentPivotVariables
      return pivotVariable.map((pv: PivotVariable) => {
        return {
          text: pv.variable,
          value: pv.variable
        }
      })
    }
  }
})
