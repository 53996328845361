<template>
<v-row wrap>
  <v-col cols="12">
    <h3 class="fitscore-title">Fit score at</h3> ({{ insertionDate }})
  </v-col>
  <v-col cols="12" v-if="fitscore">
    <FitScore
      :fitScoreValue="fitscore">
    </FitScore>
  </v-col>
  <v-col v-else cols="12" class="fitscore-sentence">
    There was no fitscore present when the briefing instructions were sent
  </v-col>
</v-row>
</template>

<script>
import FitScore from '@/components/TableComponents/Form/FormFragments/FitScore'

export default {
  name: 'FitScoreTraderView',
  components: { FitScore },
  props: {
    fitscore: {
      type: Number
    },
    insertionDate: {
      type: String
    }
  }
}
</script>

<style scoped>
.fitscore-title {
  display: inline;
}

.fitscore-sentence {
  font-style: italic;
}
</style>
