
import { defineComponent } from 'vue'
import DuoDatePicker from '@/components/TableComponents/Form/FormFragments/DuoDatePicker.vue'
import _ from 'lodash'
import { isNotNullOrUndefined } from '../../../utils/commonUtils'

export default defineComponent({
  name: 'InstruLogCard',
  components: { DuoDatePicker },
  props: {
    ioId: {
      type: String,
      required: true
    },
    dsp: {
      type: String,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      defaultSort: 'log_date',
      sortDesc: true,
      logs: [],
      tableHeaders: [
        { text: 'Date', value: 'log_date' },
        { text: 'Changed by', value: 'user_mail' },
        { text: 'Category', value: 'category' },
        { text: 'Changes', value: 'changes' }
      ],
      laterThan: '',
      logsError: false,
      loading: false,
      search: '',
      changedBySearch: [],
      categorySearch: [],
      startDate: '',
      endDate: ''
    }
  },
  async mounted () {
    await this.getLogs()
  },
  methods: {
    async getLogs () {
      this.loading = true
      console.log(this.loading)
      this.logs = []
      this.logsError = false
      const response = await this.$apiCaller.getInstructionLogs(this.dsp, this.ioId, true)
      if (this.$apiCaller.isResponseError(response)) {
        this.logsError = true
      } else {
        this.logs = response.data
      }
      this.loading = false
    },
    formatDate (dateStr: string): string {
      const dateObj = new Date(dateStr)
      const formatted = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()} ${dateObj.getUTCHours()}:${dateObj.getUTCMinutes()}:${dateObj.getUTCSeconds()} UTC`
      return formatted
    },
    close () {
      this.logs = []
      this.logsError = false
      this.$emit('close')
    },
    checkDateInsideBounds (startDateStr: string, endDateStr: string, dateStr: string) {
      if (startDateStr === '' || endDateStr === '') return true
      const startDateObj = new Date(startDateStr)
      const endDateObj = new Date(endDateStr)
      const dateObj = new Date(dateStr)
      return (startDateObj <= dateObj) && (dateObj <= endDateObj)
    },
    categoryRequiresInstructionId (category: string): boolean {
      return ['model'].includes(category.toLowerCase())
    },
    searchIncludesValue (search: string[], value: string): boolean {
      // value has to include at least one of the search values
      return search.length === 0 || search.some((searchValue) => value.toLowerCase().includes(searchValue.toLowerCase()))
    }

  },
  watch: {
    async dialog (value) {
      if (value) await this.getLogs()
    }
  },
  computed: {
    searchedLogs: function (): any[] {
      let logs = _.cloneDeep(this.logs)
      if (logs.length === 0) return []
      let filteredByUser = logs.filter((log) => {
        return this.searchIncludesValue(this.changedBySearch, log.user_mail)
      })
      let filteredByDate = filteredByUser.filter((log) => {
        return this.checkDateInsideBounds(this.startDate, this.endDate, log.log_date)
      })
      let filteredByCategory = filteredByDate.filter((log) => {
        return this.searchIncludesValue(this.categorySearch, log.category)
      })
      return filteredByCategory
    },
    userMails: function (): string[] {
      if (isNotNullOrUndefined(this.logs) && this.logs.length > 0) {
        return this.logs
          .map((log) => log.user_mail)
          .filter(
            (value, index, currentValue) => currentValue.indexOf(value) === index
          )
      }
      return []
    },
    categories: function (): string[] {
      if (isNotNullOrUndefined(this.logs) && this.logs.length > 0) {
        return this.logs
          .map((log) => log.category.toUpperCase())
          .filter(
            (value, index, currentValue) => currentValue.indexOf(value) === index
          )
      }
      return []
    }
  }
})
