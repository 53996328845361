import { SurcoucheForcedRatioApiArgs } from '../../../types/surcouche_forced_ratio_types'

export default class SurcoucheForcedRatioModel {
  id: number
  columns: Array<string>
  comment?: string
  uploadDate: Date
  transactionID: number

  constructor (apiObject: SurcoucheForcedRatioApiArgs = null) {
    if (apiObject != null) {
      this.id = apiObject.id
      this.columns = apiObject.columns
      this.comment = apiObject.comment != null ? apiObject.comment : null
      this.uploadDate = new Date(apiObject.upload_date)
      this.transactionID = apiObject.transaction_id
    }
  }
}
