
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

import { dateToStringYMD, formatDate, createCsvStringFromObjectWithHeaders, downloadFileHandler } from '../../../../../utils/commonUtils'
import { DspInstruction } from '../../../../../types/instruction_type'
import CsvInputFileDialog from '../../../TableComponents/Form/FormFragments/CsvInputFileDialog.vue'
import SimpleDialog from '../FormFragments/SimpleDialog.vue'
import SurcoucheForcedRatioModel from '../../../../models/SurcoucheForcedRatio/SurcoucheForcedRatioModel'
import InstructionGetterSetter from '../../../../models/Overlay/InstructionGetterSetter'
import { InstructionDsp } from 'types/instruction_type'

export default defineComponent({
  name: 'IOSurcoucheForceRatio',
  components: {
    CsvInputFileDialog,
    SimpleDialog
  },
  props: {
    io: {
      type: Object as PropType<DspInstruction>,
      required: true
    },
    dsp: {
      type: Object as PropType<InstructionDsp>,
      required: true
    }
  },
  data () {
    return {
      insGetterSetter: new InstructionGetterSetter(this.io, this.dsp),
      items: [],
      tableHeaders: [
        { text: 'Upload date', align: 'start', sortable: true, value: 'uploadDate' },
        { text: 'Variables', align: 'start', sortable: true, value: 'variables' },
        { text: 'Comment', align: 'start', sortable: true, value: 'comment' },
        { text: '', align: 'start', sortable: false, value: '' }
      ],
      loading: true,
      loaded: false,
      totalItems: 0,
      search: '',
      templateDownloadKeysPerDspItems: [],
      selectedTemplateKeys: [],
      // Dialogs
      csvInputFileDialog: false,
      getTemplateDialogOn: false,
      sfrAuthorizedKeysPerDsp: [],
      // Ratio activation btn
      sfrActivationStatus: false, // Default if no template are uploaded
      sfrActivationStatusLoading: false
    }
  },
  mounted () {
    this.loadItems()
    this.templateDownloadKeysPerDspItems = this.sfrAuthorizedKeysPerDsp.length > 0 ? this.sfrAuthorizedKeysPerDsp : []
  },
  methods: {
    async loadItems () {
      await this.setSfrAuthorizedKeysPerDsp()
      await this.getSfrItems()
      this.totalItems = this.items.length
      this.loading = false
    },
    async setSfrAuthorizedKeysPerDsp () {
      const response = await this.$apiCaller.getSurcoucheForcedRatioDspConfig(this.dsp)
      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling getSurcoucheForcedRatioDspConfig.')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        this.sfrAuthorizedKeysPerDsp = response.data
        console.log('sfrAuthorizedKeysPerDsp', this.sfrAuthorizedKeysPerDsp)
      }
    },
    formatDate (date: Date) {
      return formatDate(date.toISOString(), false)
    },
    dateToStringYMD (date: Date) {
      return dateToStringYMD(date)
    },
    // DIALOGS
    openCsvInputFileDialog () {
      this.csvInputFileDialog = true
    },
    closeCsvInputFileDialog () {
      this.csvInputFileDialog = false
    },
    openGetTemplateDialog () {
      this.getTemplateDialogOn = true
    },
    closeGetTemplateDialog () {
      this.getTemplateDialogOn = false
    },
    getTemplate () {
      let headerLine = ''
      // Writing header columns
      this.selectedTemplateKeys.forEach((column: string) => {
        headerLine += `${headerLine.length > 0 ? ',' : ''}${column}`
      })
      headerLine += `,ratio`

      // Writing example line with "--" at the start to indicate it is a comment (in case of..... you know, users)
      const templateLineStart = '--'
      const templateLineRatio = ',{enter the ratio value you want to apply here, ratio must be number between 0 and 99, decimal accepted}'
      const templateLineEnd = 'DELETE THIS LINE BEFORE UPLOADING THE CSV FILE'
      let templateLine = ''
      this.selectedTemplateKeys.forEach((column: string) => {
        templateLine += `${templateLine.length > 0 ? ',' : ''}{enter ${column} values here, 1 per line}`
      })
      const fullTemplateLine = `${templateLineStart}${templateLine}${templateLineRatio} - ${templateLineEnd}`
      const fileContent = `${headerLine}\n${fullTemplateLine}`
      const fileName = `surcouche_forced_ratio_templale.csv`
      downloadFileHandler(fileName, fileContent, 'text/csv')
    },
    // ENDPOINT CALL
    async getSfrItems () {
      if (this.loaded) {
        return
      }
      const response = await this.$apiCaller.getSurcoucheForcedRatios(this.dsp, this.insGetterSetter.clientIdPerDsp, this.io.id)
      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api getSurcoucheForcedRatios')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.items = [] // reset items
        response.data.templates.forEach((data: any) => {
          this.items.push(new SurcoucheForcedRatioModel(data))
        })
        this.items.sort((a: SurcoucheForcedRatioModel, b: SurcoucheForcedRatioModel) => {
          return b.uploadDate.getTime() - a.uploadDate.getTime()
        })
        this.sfrActivationStatus = response.data.activation_status
        this.loaded = true
      }
    },
    async inputSuccess (fileContent: string, comment: string | null = null) {
      const data: { [key: string]: string | null } = {
        content: fileContent,
        comment: comment
      }
      // editedItem.id is the overview.external_id
      const memberIDVarName = (this.dsp)
      const response = await this.$apiCaller.postNewSurcoucheForcedRatio(this.dsp, this.insGetterSetter.clientIdPerDsp, this.io.id, data)
      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api postNewSurcoucheForcedRatio')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.closeCsvInputFileDialog()
        this.$store.commit('setSuccessMessageWithResponse', 'CSV file successfully uploaded.')
        this.loaded = false
        await this.loadItems()
      }
    },
    async downloadCsv (transactionID: string, uploadDate: Date) {
      const response = await this.$apiCaller.getSurcoucheForcedRatio(this.io.id, this.dsp, transactionID)
      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api getSurcoucheForcedRatio')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        const fileContent = createCsvStringFromObjectWithHeaders(response.data.values, response.data.columns)
        const fileName = `overview_${this.io.id}_ratios_${formatDate(uploadDate.toISOString())}.csv`
        downloadFileHandler(fileName, fileContent, 'text/csv')
      }
    },
    // Activate or deactivate ratios
    async activateOrDeactivateRatios () {
      this.sfrActivationStatusLoading = true
      const response = await this.$apiCaller.putSurcoucheForcedRatioActivation(this.dsp, this.insGetterSetter.clientIdPerDsp, this.io.id)
      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api putSurcoucheForcedRatioActivation')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.sfrActivationStatus = !this.sfrActivationStatus
      }
      this.sfrActivationStatusLoading = false
    }
  },
  computed: {
    activateOrDeactivateRatiosLabel (): string {
      return this.sfrActivationStatus ? 'deactivate' : 'activate'
    },
    activateOrDeactivateRatiosBtnColor (): string {
      return this.sfrActivationStatus ? 'error' : 'primary'
    }
  }
})
