
import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { ioBriefMixins } from '../../../../mixins/instructions'
import InstructionGetterSetter from '../../../../models/Overlay/InstructionGetterSetter'
import LocalizationHeaderForm from '../FormFragments/LocalizationHeaderForm.vue'
import { isSetAndNotEmpty } from '../../../../../utils/commonUtils'
import { DRAFT, TO_VALIDATE, VALIDATED, REMOVED } from '../../../../../store'
import BriefModel from '@/models/Briefs/BriefModel'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import { isActivatedCheckPreMep } from '../../../../../config/featureRefHelper'
import { InstructionDsp } from '../../../../../types/instruction_type'

@Component({
  components: {
    LocalizationHeaderForm
  }
})
export default class IOFormHeader extends mixins(ioBriefMixins) {
  @Prop({ required: true, default: false }) isInit: boolean
  @Prop({ required: true, default: '' }) editedItemIo: boolean
  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: true, default: '' }) formTitle: string
  @Prop({ required: true, default: false }) isLocalizationHeaderForm: boolean
  @Prop({ required: true, default: false }) acknowledgmentsAreAllOk: boolean
  @Prop({ required: true, default: false }) isLoadingSurcoucheConfig: boolean
  @Prop({ required: true, default: false }) formIsValidated: boolean
  @Prop({ required: true }) brief: BriefModel

  openMenu: boolean = false

  mounted () {}

  // SAVES & EMIT
  actionMainSave () {
    if (this.displaySaveAsValidated) {
      this.saveAsValidated()
    } else if (this.displaySaveAsReview) {
      this.saveAsReview()
    } else if (this.displaySaveAsDraft) {
      this.saveAsDraft()
    }
  }

  saveNormal () {
    this.$emit('saveNormal')
  }

  saveAsDraft () {
    this.$emit('saveAsDraft')
  }

  saveAsReview () {
    this.$emit('saveAsReview')
  }

  saveAsValidated () {
    this.$emit('saveAsValidated')
  }

  emitClose () {
    this.$emit('close')
  }

  // LABELS
  get labelMainSave () {
    if (this.displaySaveAsValidated) {
      return this.labelSaveAsValidated
    } else if (this.displaySaveAsReview) {
      return this.labelSaveForReview
    } else if (this.displaySaveAsDraft) {
      return this.labelSaveAsDraft
    } else {
      return this.labelSaveNormal
    }
  }
  get labelSaveNormal () { return 'Save' }
  get labelSaveAsDraft () { return 'Save as draft' }
  get labelSaveForReview () { return 'Save for review' }
  get labelSaveAsValidated () { return 'Save as validated' }

  // DISPLAY
  get displaySaveNormal () {
    if (!this.isCheckPreMepValid) {
      return false
    }
    const clientId = this.insGetterSetter.clientIdPerDsp
    return (!this.isCuiCuiMember(clientId) ||
        (this.isCuiCuiMember(clientId) && [VALIDATED, REMOVED].includes(this.insGetterSetter.status))) &&
      this.acknowledgmentsAreAllOk
  }

  get displaySaveAsReview () {
    if (!this.isCheckPreMepValid) {
      return false
    }

    if (this.brief && this.brief.isAutoplugsBrief) {
      return false
    }
    const clientId = this.insGetterSetter.clientIdPerDsp
    return this.isCuiCuiMember(clientId) && ([DRAFT, TO_VALIDATE, REMOVED].includes(this.insGetterSetter.status) ||
      !isSetAndNotEmpty(this.insGetterSetter.status)) && this.acknowledgmentsAreAllOk
  }

  get displaySaveAsDraft () {
    if (this.isOnAutoplugsModeAndNotValidated) {
      return true
    }

    if (!this.isCheckPreMepValid) {
      return true
    }

    const clientId = this.insGetterSetter.clientIdPerDsp
    return this.isCuiCuiMember(clientId) && ([DRAFT, REMOVED].includes(this.insGetterSetter.status) ||
      !isSetAndNotEmpty(this.insGetterSetter.status))
  }

  get displaySaveAsValidated () {
    if (!this.isCheckPreMepValid) {
      return false
    }

    if (this.isOnAutoplugsModeAndNotValidated) {
      return true
    }
    const clientId = this.insGetterSetter.clientIdPerDsp
    return this.isCuiCuiMember(clientId) && this.isFastTrackCuiCuiMember(clientId) &&
      ([DRAFT, TO_VALIDATE, REMOVED].includes(this.insGetterSetter.status) ||
        !isSetAndNotEmpty(this.insGetterSetter.status)) && this.acknowledgmentsAreAllOk
  }

  get getLocalizationHeaderForm () {
    return this.$refs['localization-header-form']
  }

  normalSaveButton () {
    const clientId = this.insGetterSetter.clientIdPerDsp
    return this.isCheckPreMepValid && (!this.isCuiCuiMember(clientId) || this.insGetterSetter.status === VALIDATED)
  }

  get isOnAutoplugsModeAndNotValidated () {
    return this.brief && this.brief.isAutoplugsBrief && ![VALIDATED, REMOVED].includes(this.insGetterSetter.status)
  }

  openSaveMenu (event: any) {
    this.openMenu = !this.openMenu
    event.preventDefault()
  }

  isActivatedCheckPreMep () {
    const newFeaturesRef: NewFeatureReferenceModel[] = this.$store.getters.getCurrentNewFeatureReferences
    if (!newFeaturesRef) {
      console.warn('No new features ref found at the moment.')
      return false
    }
    return isActivatedCheckPreMep(newFeaturesRef, this.$route.params.dsp as InstructionDsp)
  }

  get isCheckPreMepValid () {
    if (!this.isActivatedCheckPreMep()) {
      console.warn('Check pre mep is not activated for this instruction.')
      return true
    }
    // if the instruction is already validated,
    // the form can be saved even if the checkpremep is not valid
    if (this.insGetterSetter.status === VALIDATED) {
      return true
    }

    // if the status of the user is debugger, the user can save without a valid checkpremep.
    const userStatus = this.$store.getters.getUserStatus

    if (userStatus.isDebugger) {
      return true
    }

    return this.$store.getters.isCheckPreMepValid
  }
}

